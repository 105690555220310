import React from "react";

export default ({ userData }) => {
  return (
    <>
      <h4>Welcome to HoPE</h4>
      <div className="video-container">
        <iframe
          title="page1Video"
          width="716"
          height="403"
          src="https://www.youtube-nocookie.com/embed/7Tu56FejCE4?rel=0;modestbranding=1"
          frameBorder="0"
          allowFullScreen="allowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
          msallowfullscreen="msallowfullscreen"
          oallowfullscreen="oallowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
        ></iframe>
      </div>
    </>
  );
};
