import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Breakpoint } from "react-socks";

const currentMedEquipUsageOptions = ["Yes", "No", "I'm not sure"];

export default ({ userData, updateUserData }) => {
  const { activity7b = {} } = userData;
  const { myCurrentMedEquipUsage = null } = activity7b;

  let mediumUpCol3Array = [];
  let mediumUpCol4Array = [];

  for (let i = 0; i < currentMedEquipUsageOptions.length; i++) {
    mediumUpCol3Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={myCurrentMedEquipUsage === currentMedEquipUsageOptions[i]}
          className="mb-3"
          id="medUp-option-button-activity1"
          onClick={() => {
            updateUserData({
              activity7b: {
                myCurrentMedEquipUsage: currentMedEquipUsageOptions[i],
              },
            });
          }}
        >
          {currentMedEquipUsageOptions[i]}
        </Button>
      </Row>
    );
  }

  for (let i = 0; i < currentMedEquipUsageOptions.length; i++) {
    mediumUpCol4Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={myCurrentMedEquipUsage === currentMedEquipUsageOptions[i]}
          className="mb-3"
          id="medUp-option-button-activity1-phone"
          onClick={() => {
            updateUserData({
              activity7b: {
                myCurrentMedEquipUsage: currentMedEquipUsageOptions[i],
              },
            });
          }}
        >
          {currentMedEquipUsageOptions[i]}
        </Button>
      </Row>
    );
  }

  return (
    <>
      <Breakpoint medium up>
        <div id="question-container">
          <h6>
            It would also be helpful for the hospice team to know if you are
            using any medical equipment at home. Pick the option below that best
            applies to you.
          </h6>
        </div>
        <br />
        <br />
        <div id="title-div">
          <h4>
            Are you using any medical equipment at home (oxygen, nebulizer,
            feeding tube)?
          </h4>
          <h5>(select one)</h5>
        </div>
        <br />
        <Row>
          <Col>{mediumUpCol3Array}</Col>
        </Row>
      </Breakpoint>
      <Breakpoint small down>
        <div id="question-container">
          <h6>
            It would also be helpful for the hospice team to know if you are
            using any medical equipment at home, as well as what your home
            situation consists of. Pick the options below that best describe
            your responses to those questions.
          </h6>
        </div>
        <br />
        <br />
        <h4>
          Are you using any medical equipment at home (oxygen, nebulizer,
          feeding tube)?
        </h4>
        <h5>(select one)</h5>
        <br />
        <Row>
          <Col>{mediumUpCol4Array}</Col>
        </Row>
      </Breakpoint>
    </>
  );
};
