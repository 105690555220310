import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Breakpoint } from "react-socks";

const symptomOptions = [
  "Pain",
  "Nausea",
  "Shortness of breath",
  "Constipation",
  "Tiredness",
  "Drowsiness",
  "Lack of appetite",
  "Depression",
  "Anxiety",
  "Skin issues",
  "Trouble falling asleep",
  "Something else"
];

export default ({ userData, updateUserData }) => {
  const { activity2b = {} } = userData;
  const { mySymptoms = [] } = activity2b;

  let mediumUpCol1Array = [];
  let mediumUpCol2Array = [];

  for (let i = 0; i < Math.round(symptomOptions.length / 2); i++) {
    mediumUpCol1Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={mySymptoms.indexOf(symptomOptions[i]) !== -1}
          className="mb-3"
          id="medUp-option-button"
          onClick={() => {
            let myNewSymptoms = [];
            if (mySymptoms.indexOf(symptomOptions[i]) === -1) {
              myNewSymptoms[i] = symptomOptions[i];
              updateUserData({
                activity2b: {
                  mySymptoms: myNewSymptoms
                }
              });
            } else {
              myNewSymptoms[i] = null;
              updateUserData({
                activity2b: {
                  mySymptoms: myNewSymptoms
                }
              });
            }
          }}
        >
          {symptomOptions[i]}
        </Button>
      </Row>
    );
  }

  for (
    let i = Math.round(symptomOptions.length / 2);
    i < symptomOptions.length;
    i++
  ) {
    mediumUpCol2Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={mySymptoms.indexOf(symptomOptions[i]) !== -1}
          className="mb-3"
          id="medUp-option-button"
          onClick={() => {
            let myNewSymptoms = [];
            if (mySymptoms.indexOf(symptomOptions[i]) === -1) {
              myNewSymptoms[i] = symptomOptions[i];
              updateUserData({
                activity2b: {
                  mySymptoms: myNewSymptoms
                }
              });
            } else {
              myNewSymptoms[i] = null;
              updateUserData({
                activity2b: {
                  mySymptoms: myNewSymptoms
                }
              });
            }
          }}
        >
          {symptomOptions[i]}
        </Button>
      </Row>
    );
  }

  return (
    <>
      <div id="question-container">
        <h6>
          Your care team would benefit from learning what is causing you
          distress. In this activity, select any symptoms that are bothering
          you.
        </h6>
      </div>
      <br />
      <br />
      <Breakpoint medium up>
        <div id="title-div">
          <h4>What symptoms are currently bothering you?</h4>
          <h5>(select all that apply)</h5>
        </div>
      </Breakpoint>
      <Breakpoint small down>
        <h4>What symptoms are currently bothering you?</h4>
        <h5>(select all that apply)</h5>
      </Breakpoint>
      <br />
      <Breakpoint small down>
        {symptomOptions.map((thing, i) => (
          <Row key={i}>
            <Button
              variant="light"
              active={mySymptoms.indexOf(thing) !== -1}
              className="mb-3"
              id="medUp-option-button-activity1-phone"
              onClick={() => {
                let myNewSymptoms = [];
                if (mySymptoms.indexOf(thing) === -1) {
                  myNewSymptoms[i] = thing;
                  updateUserData({
                    activity3: {
                      mySymptoms: myNewSymptoms
                    }
                  });
                } else {
                  myNewSymptoms[i] = null;
                  updateUserData({
                    activity3: {
                      mySymptoms: myNewSymptoms
                    }
                  });
                }
              }}
            >
              {thing}
            </Button>
          </Row>
        ))}
      </Breakpoint>
      <Breakpoint medium up>
        <Row>
          <Col className="mr-5">{mediumUpCol1Array}</Col>
          <Col>{mediumUpCol2Array}</Col>
        </Row>
      </Breakpoint>
    </>
  );
};
