import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Breakpoint } from "react-socks";

const careLocationOptions = ["Yes", "No", "I'm not sure"];

export default ({ userData, updateUserData }) => {
  const { activity1 = {} } = userData;
  const { myCareLocation = null } = activity1;

  let mediumUpCol1Array = [];
  let mediumUpCol2Array = [];

  for (let i = 0; i < careLocationOptions.length; i++) {
    mediumUpCol1Array.push(
      <Row key={i}>
        <Button
          variant="light"
          active={myCareLocation === careLocationOptions[i]}
          className="mb-3"
          id="medUp-option-button-activity1"
          onClick={() => {
            updateUserData({
              activity1: {
                myCareLocation: careLocationOptions[i],
              },
            });
          }}
        >
          {careLocationOptions[i]}
        </Button>
      </Row>
    );
  }

  for (let i = 0; i < careLocationOptions.length; i++) {
    mediumUpCol2Array.push(
      <Row key={i}>
        <Button
          variant="light"
          active={myCareLocation === careLocationOptions[i]}
          className="mb-3"
          id="medUp-option-button-activity1-phone"
          onClick={() => {
            updateUserData({
              activity1: {
                myCareLocation: careLocationOptions[i],
              },
            });
          }}
        >
          {careLocationOptions[i]}
        </Button>
      </Row>
    );
  }

  return (
    <>
      <div id="question-container">
        <h6>
          In the previous video, James decided, with help from his family and
          doctor, that he wanted to receive most of his care at home, rather
          than in the hospital or clinic. For the following statement, think
          about where you want to receive most of your care.
        </h6>
      </div>
      <br />
      <br />
      <Breakpoint medium up>
        <div id="title-div">
          <h4>
            Would you prefer to receive most of your care at home, if possible?
          </h4>
          <h5>(select one)</h5>
        </div>
      </Breakpoint>
      <Breakpoint small down>
        <h4>
          Would you prefer to receive most of your care at home, if possible?
        </h4>
        <h5>(select one)</h5>
      </Breakpoint>
      <br />
      <Breakpoint medium up>
        <Row>
          <Col>{mediumUpCol1Array}</Col>
        </Row>
      </Breakpoint>
      <Breakpoint small down>
        <Row>
          <Col>{mediumUpCol2Array}</Col>
        </Row>
      </Breakpoint>
    </>
  );
};
