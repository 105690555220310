import { db, auth, firebase, secondaryApp } from 'services/firebase';
import { createUser, deleteUser, restoreUser } from 'services/user';

export const SUPERUSER_TYPES = {
  ADMIN: 'ADMIN',
  LIAISON: 'LIAISON'
};

export const createSuperuser = async ({ email, initialPassword, type = SUPERUSER_TYPES.LIAISON, organizationId }) => {
  try {
    const { user } = await secondaryApp.auth.createUserWithEmailAndPassword(email, initialPassword);
    await secondaryApp.auth.signOut();
    const superuserRef = db.collection('users');
    const superuserData = type === SUPERUSER_TYPES.ADMIN ? {
      type,
      email
    } : {
        type,
        email,
        organization: db.doc(`organizations/${organizationId}`),
        requirePasswordReset: true
      };
    await superuserRef.doc(user.uid).set(superuserData);
    if (type === SUPERUSER_TYPES.LIAISON) {
      const organizationRef = await db.collection('organizations').doc(organizationId);
      await organizationRef.update({
        liaisons: firebase.firestore.FieldValue.arrayUnion(db.doc(`users/${user.uid}`))
      });
    }
    return user;
  } catch (error) {
    console.log(`superuser ${type} create error`, error);
    return false;
  }
}

export const loginSuperuser = async (email, password) => {
  try {
    await auth.signInWithEmailAndPassword(email, password);
    return true;
  } catch (error) {
    console.log(`superuser signin error`, error);
    return false;
  }
}

export const createOrganization = async ({ name, url }) => {
  try {
    const organizationRef = db.collection('organizations');
    const organizationData = {
      name,
      url,
      liaisons: [],
      cohorts: []
    };
    const docRef = await organizationRef.add(organizationData);
    return docRef;
  } catch (error) {
    console.log('error creating new organization', error);
    return false;
  }
}

export const createCohort = async ({ organizationRef, name }) => {
  try {
    const cohortData = {
      name,
      organization: organizationRef,
      users: []
    };
    const cohortRef = await db.collection('cohorts').add(cohortData);
    await organizationRef.update({
      cohorts: firebase.firestore.FieldValue.arrayUnion(cohortRef)
    });
  } catch (error) {
    console.log('error creating cohort', error);
    return false;
  }
}

export const createCohortUser = async (cohortRef) => {
  try {
    return await createUser(cohortRef, {}, secondaryApp.auth);
  } catch (error) {
    console.log('error creating cohort user', error);
    return false;
  }
};

export const deleteCohortUser = async (cohortRef, userRef) => {
  try {
    return await deleteUser(userRef, cohortRef);
  } catch (error) {
    console.log('error deleting cohort user', error);
    return false;
  }
}

export const restoreCohortUser = async (cohortRef, userRef) => {
  try {
    return await restoreUser(userRef, cohortRef);
  } catch (error) {
    console.log('error restoring cohort user', error);
    return false;
  }
}