import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Button,
  Table,
  ListGroup,
  Row,
  Col,
  ProgressBar,
  Accordion,
  Card
} from "react-bootstrap";

import pageConfig from "config/pageConfig";
import {
  createCohort,
  createCohortUser,
  deleteCohortUser,
  restoreCohortUser
} from "services/admin";
import { db } from "services/firebase";

import styles from "./styles.module.scss";
import Collapsible from "react-collapsible";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const LiaisonHome = ({ userData }) => {
  const [fetchedCohortData, setFetchedCohortData] = useState(null);
  const [showCreateCohortModal, setShowCreateCohortModal] = useState(false);
  const [createCohortModalLoading, setCreateCohortModalLoading] = useState(
    false
  );
  const [newCohortName, setNewCohortName] = useState("");
  const [showDeleteUserTarget, setShowDeleteUserTarget] = useState(null);
  const [showRestoreUserTarget, setShowRestoreUserTarget] = useState(null);
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);

  const createNewCohort = async (e) => {
    e.preventDefault();
    setCreateCohortModalLoading(true);

    const { organization: organizationRef } = userData;
    const newCohortData = await createCohort({
      organizationRef,
      name: newCohortName,
    });

    setCreateCohortModalLoading(false);
    setNewCohortName("");
    if (newCohortData) {
      setShowCreateCohortModal(false);
    } else {
      // TODO show error
    }
  };

  const createNewUser = async (cohortRef) => {
    await createCohortUser(cohortRef);
    // TODO set loading, handle errors, etc.
  };

  const confirmDeleteUser = async (cohortRef, userRef) => {
    await deleteCohortUser(cohortRef, userRef);
    // TODO set loading, handle errors, etc.
    setShowDeleteUserTarget(null);
  };

  const confirmRestoreUser = async (cohortRef, userRef) => {
    await restoreCohortUser(cohortRef, userRef);
    // TODO set loading, handle errors, etc.
    setShowRestoreUserTarget(null);
  };

  useEffect(() => {
    const unsubscribe = db
      .collection("cohorts")
      .where("organization", "==", userData.organization)
      .onSnapshot(async (cohortQuerySnapshot) => {
        const cohortData = await Promise.all(
          cohortQuerySnapshot.docs.map(async (cohortDoc) => {
            const cohort = cohortDoc.data();
            const users = await Promise.all(
              cohort.users.map(async (userRef) => {
                const userDoc = await userRef.get();
                const user = await userDoc.data();
                const visitedPaths = user.visitedPaths || {};
                const progress = Math.floor(
                  (Object.keys(visitedPaths).reduce((acc, path) => {
                    const pageIndex = pageConfig.findIndex(
                      (page) => page.path === path
                    );
                    return pageIndex > acc ? pageIndex : acc;
                  }, 0) / (pageConfig.length - 1)) * 100
                );
                return {
                  id: userRef.id,
                  passcode: user.passcode,
                  disabled: !!user.disabled,
                  progress,
                  ref: userRef
                };
              })
            );
            return {
              ...cohort,
              ref: cohortDoc.ref,
              users
            };
          })
        );
        setFetchedCohortData(cohortData);
      });

    return () => {
      unsubscribe();
    };
  }, [userData.organization]);

  return (
    <>
      <Modal
        show={showCreateCohortModal}
        onHide={() => setShowCreateCohortModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Cohort</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fetchedCohortData && (
            <Form onSubmit={createNewCohort}>
              <Form.Group>
                <Form.Label>Cohort name:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter cohort name"
                  disabled={createCohortModalLoading}
                  value={newCohortName}
                  onChange={(e) => setNewCohortName(e.target.value)}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              createCohortModalLoading ||
              !newCohortName ||
              newCohortName.length < 1
            }
            variant="primary"
            onClick={createNewCohort}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={!!showDeleteUserTarget}
        onHide={() => setShowDeleteUserTarget(null)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showDeleteUserTarget &&
            showDeleteUserTarget.user &&
            showDeleteUserTarget.cohort && (
              <>
                <div>
                  Are you sure you wish to delete user:{" "}
                  <b>{`${showDeleteUserTarget.user.passcode}?`}</b>
                </div>
                <Button
                  variant="danger"
                  onClick={() =>
                    confirmDeleteUser(
                      showDeleteUserTarget.cohort.ref,
                      showDeleteUserTarget.user.ref
                    )
                  }
                >
                  Confirm
                </Button>
                <Button onClick={() => setShowDeleteUserTarget(null)}>
                  Cancel
                </Button>
              </>
            )}
        </Modal.Body>
      </Modal>
      <Modal
        show={!!showRestoreUserTarget}
        onHide={() => setShowRestoreUserTarget(null)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Restore User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showRestoreUserTarget &&
            showRestoreUserTarget.user &&
            showRestoreUserTarget.cohort && (
              <>
                <div>
                  {`Are you sure you wish to restore user: `}
                  <b>{`${showRestoreUserTarget.user.passcode}?`}</b>
                </div>
                <Button
                  variant="danger"
                  onClick={() =>
                    confirmRestoreUser(
                      showRestoreUserTarget.cohort.ref,
                      showRestoreUserTarget.user.ref
                    )
                  }
                >
                  Confirm
                </Button>
                <Button onClick={() => setShowRestoreUserTarget(null)}>
                  Cancel
                </Button>
              </>
            )}
        </Modal.Body>
      </Modal>
      <Row>
        <h3>Liaison Home</h3>
      </Row>
      <Row>
        <Col xs={10}>
          <Button onClick={() => setShowCreateCohortModal(true)}>
            Create New Cohort
        </Button>
        </Col>
        <Col>
          <Form.Check
            type="checkbox"
            value={showDeletedUsers}
            label={`Show Deleted Users`}
            onClick={() => setShowDeletedUsers(!showDeletedUsers)}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          {fetchedCohortData &&
            fetchedCohortData.map((cohort) => (
              <Collapsible trigger={cohort.name} triggerTagName="h4">
                <div>
                  <Card.Body>
                    <Row>
                      <Button
                        className="ml-auto mb-2"
                        onClick={() => createNewUser(cohort.ref)}
                      >
                        Create New User
                      </Button>
                    </Row>
                    {cohort.users && cohort.users.length > 0 && (
                      <Row>
                        <Table
                          responsive
                          striped
                          bordered
                          hover
                          className={styles.cohortTable}
                        >
                          <thead>
                            <tr>
                              <th style={{ width: "160px" }}>User Passcode</th>
                              <th style={{ width: "auto" }}>Status</th>
                              <th style={{ width: "120px" }}>Summary</th>
                              <th style={{ width: "120px" }}>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cohort.users.filter(user => showDeletedUsers ? true : !user.disabled).map((user) => (
                              <tr key={user.passcode}>
                                <td className={user.disabled ? styles.deletedUser : ''}>{user.passcode}</td>
                                <td>
                                  <ProgressBar
                                    className={styles.progressWrapper}
                                    now={user.progress}
                                    label={`${user.progress}%`}
                                  />
                                </td>
                                <td>
                                  <Button
                                    size="sm"
                                    as="a"
                                    href={`/summary/${user.id}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    Link
                                  </Button>
                                </td>
                                <td>
                                  {user.disabled ? (
                                    <Button
                                      size="sm"
                                      variant="success"
                                      onClick={() => setShowRestoreUserTarget({ cohort, user })}
                                    >
                                      Restore
                                    </Button>
                                  ) : (
                                      <Button
                                        size="sm"
                                        variant="danger"
                                        onClick={() => setShowDeleteUserTarget({ cohort, user })}
                                      >
                                        Delete
                                      </Button>
                                    )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Row>
                    )}
                  </Card.Body>
                </div>
              </Collapsible>
            ))}
        </Col>
      </Row>
    </>
  );
};

export default LiaisonHome;
