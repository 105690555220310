import React, { useState } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";

import styles from "./styles.module.scss";

import { BreakpointProvider } from "react-socks";
import { Container, Row, Col } from "react-bootstrap";
import { useSwipeable } from 'react-swipeable';

export default ({ children }) => {
  const [expanded, setExpanded] = useState(false);
  const handlers = useSwipeable({
    onSwipedLeft: () => setExpanded(false)
  });
  return (
    <>
      <BreakpointProvider>
        <Header expanded={expanded} setExpanded={setExpanded} />
        <div
          className={[styles.fillHeight, styles.horizontalLayout].join(' ')}
          {...handlers}
        >
          <Sidebar expanded={expanded} />
          <Container
            fluid={true}
            className={[styles.fillHeight, styles.containerOverride].join(' ')}
          >
            <Row noGutters={true} className={styles.fillHeight}>
              <Col m={{ span: 10, offset: 1 }}>
                <div className={[styles.fillHeight, styles.pageContent].join(' ')}>
                  {children}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </BreakpointProvider>
    </>
  );
};