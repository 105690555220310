import React from "react";
import { Container } from "react-bootstrap";

export default (props) => (
  <>
    <Container
      id="main-box"
      xl={{ span: 6, offset: 3 }}
      lg={{ span: 6, offset: 3 }}
      md={{ span: 8, offset: 2 }}
      sm={{ span: 6, offset: 3 }}
    >
      <h4>HoPE Resource Library</h4>
      <p>
        For additional resources on hospice care, please see the links below:
      </p>
      <p>
        <a
          href="http://hospicefoundation.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Hospice Foundation of America
        </a>
        <br />
        The Hospice Foundation of America (HFA) is a nonprofit organization that
        provides resources for patients and their families about hospice and
        hospice-related questions.
      </p>
      <p>
        <a
          href="http://www.mayoclinic.org/healthy-lifestyle/end-of-life/in-depth/hospice-care/art-20048050"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mayo Clinic
        </a>
        <br />
        The Mayo Clinic is a nationally recognized hospital in Minnesota that
        provides scientific information to patients around the country.
      </p>
      <p>
        <a
          href="http://www.cancer.org/treatment/end-of-life-care/hospice-care/what-is-hospice-care.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          American Cancer Society
        </a>
        <br />
        The American Cancer Society is a nationwide, community-based health
        organization dedicated specifically to cancer. The page linked here
        provides information about hospice care that is relevant to both cancer
        and non-cancer patients.
      </p>
      <p>
        <a
          href="http://www.nhpco.org/patients-and-caregivers/about-hospice-care/choosing-a-hospice"
          target="_blank"
          rel="noopener noreferrer"
        >
          National Hospice and Palliative Care Organization
        </a>
        <br />
        The NHPCO is an organization that provides resources to hospice and
        palliative care providers. However, their website also includes many
        resources that are helpful to patients.
      </p>
      <p>
        <a
          href="http://www.nia.nih.gov/health/what-are-palliative-care-and-hospice-care"
          target="_blank"
          rel="noopener noreferrer"
        >
          National Institute on Aging
        </a>
        <br />
        The National Institute on Aging is part of the National Institutes of
        Health (NIH), a governmental organization focused on providing health
        information. The page linked here is their page about hospice care.
      </p>
      <p>
        <a
          href="http://americanhospice.org/learning-about-hospice/"
          target="_blank"
          rel="noopener noreferrer"
        >
          American Hospice Foundation
        </a>
        <br />
        The American Hospice Foundation was an organization whose focus was on
        improving access to hospice care. Their website still exists and has a
        large resource library.
      </p>
      <p>
        <a
          href="http://www.nccn.org/patients/resources/life_with_cancer/hospice.aspx"
          target="_blank"
          rel="noopener noreferrer"
        >
          National Comprehensive Cancer Network
        </a>
        <br />
        The NCCN is an alliance of leading cancer centers that provides
        resources for patients and caregivers about a wide range of topics. The
        page linked here will take you directly to their information about
        hospice care.
      </p>
    </Container>
  </>
);
