import React, { Component } from "react";

export default class Page3 extends Component {
  render() {
    return (
      <>
        <h4>Thinking About What Care You want to Receive</h4>
        <div className="video-container">
          <iframe
            title="page3Video"
            width="716"
            height="403"
            src="https://www.youtube-nocookie.com/embed/lEFec9n-I3A?rel=0;modestbranding=1"
            frameBorder="0"
            allowFullScreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
          ></iframe>
        </div>
      </>
    );
  }
}
