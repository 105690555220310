import React, { useState, useEffect } from "react";
import { getUserData } from "services/user";

import LoadingIndicator from "components/LoadingIndicator";
import PrivateContainer from "components/PrivateContainer";
import Summary from "components/Summary";

// Here we need to load the user data manually and then pass it along to the Summary component
export default ({
  match: {
    params: { id },
  },
}) => {
  // we're getting the id of the user from the url here
  const [fetchedUserData, setFetchedUserData] = useState(null); // creating a place to store the user data

  useEffect(() => {
    async function fetchUserData() {
      // we're defining the fetch function here
      const userData = await getUserData(id); // we get the data for the user with the id from the url
      setFetchedUserData(userData); // then we set our state to hold that user data
    }

    fetchUserData(); // and we're actually calling the fetch function here (this is sort of a workaround
    // to be able to use async functions in a useEffect hook)
  }, [id]); // we only want to rerun the useEffect function when the id changes

  // we want to always wrap the output in a PrivateContainer so that it visually matches the
  // regular care plan page. If we have user data, pass it into the Summary component. If we
  // don't have user data, show a loading indicator instead.

  return (
    <PrivateContainer>
      {fetchedUserData ? (
        <Summary userData={fetchedUserData} readOnly={true} />
      ) : (
          <LoadingIndicator />
        )}
    </PrivateContainer>
  );
};
