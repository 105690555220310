import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = require(`config/firebaseConfig.${process.env.REACT_APP_FIREBASE_ALIAS}.json`);

const firebaseApp = firebase.initializeApp(firebaseConfig);
const secondaryAppInstance = firebase.initializeApp(firebaseConfig, 'secondaryApp');  // used to create other users

const db = firebaseApp.firestore();

const auth = firebase.auth();

const secondaryApp = {
  db: secondaryAppInstance.firestore(),
  auth: secondaryAppInstance.auth()
};

export { firebase, db, auth, secondaryApp };