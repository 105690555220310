import React from "react";

import deepmerge from "deepmerge";

import {
  createUser,
  getCurrentUserData,
  updateCurrentUserData,
  loginUser,
  logoutUser
} from "services/user";
import {
  loginSuperuser
} from "services/admin";
import { firebase, auth } from "services/firebase";
import { buildCustomIsMergeableObject } from "services/util";

import UserContext from "context/UserContext";

import Router from "components/Router";
import Layout from "components/Layout";

const arrayMerge = (destinationArray, sourceArray, options) => {
  for (let i = 0; i < Math.max(destinationArray.length, sourceArray.length); i++) {
    sourceArray[i] = typeof sourceArray[i] === "undefined" ?
      typeof destinationArray[i] === "undefined" ? null : destinationArray[i]
      : sourceArray[i];
  }
  return sourceArray;
};

const test = (obj) => {
  return !(obj instanceof firebase.firestore.DocumentReference);
}
const customIsMergeableObject = buildCustomIsMergeableObject(test);

class App extends React.Component {
  state = {
    initialUserFetch: true,
    userData: null,
    userDataChanged: false // whether the local user data differs from the user data in firebase
  };

  componentDidMount = async () => {
    this.subscribe();
  };

  subscribe = () => {
    this.unsubscribe = auth.onAuthStateChanged(async currentUser => {
      const userData = await getCurrentUserData();
      this.setState({
        initialUserFetch: false,
        userData
      });
    });
  };

  componentWillUnmount = () => {
    this.unsubscribe();
  };

  createUser = async () => {
    this.unsubscribe();
    const userData = createUser();
    if (userData) {
      this.setState({
        initialUserFetch: false,
        userData
      });
      this.subscribe();
      return userData;
    } else {
      this.subscribe();
      return false;
    }
  };

  loginUser = async passcode => {
    this.unsubscribe();
    const result = await loginUser(passcode);
    if (result) {
      const userData = await getCurrentUserData();
      this.setState({
        initialUserFetch: false,
        userData
      });
      this.subscribe();
      return userData;
    } else {
      this.subscribe();
      return false;
    }
  };

  // Used to login admins and liaisons
  loginSuperuser = async (email, password) => {
    this.unsubscribe();
    const result = await loginSuperuser(email, password);
    if (result) {
      const userData = await getCurrentUserData();
      this.setState({
        initialUserFetch: false,
        userData
      });
      this.subscribe();
      return userData;
    } else {
      this.subscribe();
      return false;
    }
  }

  logoutUser = async () => {
    await this.commitUserData();
    await logoutUser();
  };

  updateUserData = async userData => {
    const newUserData = deepmerge(this.state.userData, userData, {
      isMergeableObject: customIsMergeableObject,
      arrayMerge: arrayMerge
    });
    this.setState({
      userData: newUserData,
      userDataChanged: true
    });
    return true;
  };

  commitUserData = async (overrideDataChangedCheck = false) => {
    if (!overrideDataChangedCheck && !this.state.userDataChanged) return true;
    const result = await updateCurrentUserData(this.state.userData);
    if (result) {
      this.setState({
        userDataChanged: false
      });
    } else {
      // error updating user data
    }
    return result;
  };

  render() {
    const { initialUserFetch, userData, userDataChanged } = this.state;
    const isUserAuthenticated = !!userData;
    return (
      <UserContext.Provider
        value={{
          initialUserFetch,
          userData,
          userDataChanged,
          isUserAuthenticated,
          updateUserData: this.updateUserData,
          commitUserData: this.commitUserData,
          createUser: this.createUser,
          loginUser: this.loginUser,
          logoutUser: this.logoutUser,
          loginSuperuser: this.loginSuperuser
        }}
      >
        <Layout>
          <Router />
        </Layout>
      </UserContext.Provider>
    );
  }
}

export default App;
