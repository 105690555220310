import PrivateSummary from "pages/PrivateSummary";
import PageCommonQuestions from "pages/PageCommonQuestions";
import Activity1 from "pages/Activity1";
import Activity2 from "pages/Activity2";
import Activity2b from "pages/Activity2b";
import Activity3 from "pages/Activity3";
import Activity4 from "pages/Activity4";
import Activity5 from "pages/Activity5";
import Activity6 from "pages/Activity6";
import Activity7 from "pages/Activity7";
import Activity7b from "pages/Activity7b";
import Activity7c from "pages/Activity7c";
import Activity8 from "pages/Activity8";
import Activity9 from "pages/Activity9";
import Page0 from "pages/Page0";
import Page1 from "pages/Page1";
import Page2 from "pages/Page2";
import Page3 from "pages/Page3";
import Page4 from "pages/Page4";
import Page5 from "pages/Page5";
import Page6 from "pages/Page6";
import Page7 from "pages/Page7";

export const structuredPages = [
  {
    path: "/getting-started",
    component: Page0,
    title: "Getting Started",
  },
  {
    path: "/welcome",
    component: Page1,
    title: "Welcome",
  },
  {
    path: "/where-to-receive-care",
    component: Page2,
    title: "Where to Receive Care",
    subPages: [
      {
        path: "/care-location",
        component: Activity1,
        title: "Care Location",
      },
    ],
  },
  {
    path: "/care-preferences",
    component: Page3,
    title: "Care Preferences",
    subPages: [
      {
        path: "/focus-of-care",
        component: Activity2,
        title: "Focus of Care",
      },
      {
        path: "/symptoms",
        component: Activity2b,
        title: "Symptoms",
      },
    ],
  },
  {
    path: "/supporting-loved-ones",
    component: Page4,
    title: "Supporting Loved Ones",
    subPages: [
      {
        path: "/hospice-team-support",
        component: Activity3,
        title: "Hospice Team Support",
      },
    ],
  },
  {
    path: "/quality-of-life",
    component: Page5,
    title: "Quality of Life",
    subPages: [
      {
        path: "/your-hopes",
        component: Activity4,
        title: "Your Hopes",
      },
      {
        path: "/your-worries",
        component: Activity5,
        title: "Your Worries",
      },
    ],
  },
  {
    path: "/hospice-team",
    component: Page6,
    title: "Hospice Care Team",
    subPages: [
      {
        path: "/most-helpful-services",
        component: Activity6,
        title: "Most Helpful Services",
      },
      {
        path: "/currently-received-services",
        component: Activity7,
        title: "Currently Received Services",
      },
      {
        path: "/medical-equipment",
        component: Activity7b,
        title: "Medical Equipment",
      },
      {
        path: "/living-situation",
        component: Activity7c,
        title: "Living Situation",
      },
    ],
  },
  {
    path: "/common-questions",
    component: PageCommonQuestions,
    title: "Common Questions",
    subPages: [
      {
        path: "/questions-for-your-care-team",
        component: Activity8,
        title: "Questions for Your Team",
      },
    ],
  },
  {
    path: "/preparing-for-hospice",
    component: Page7,
    title: "Preparing for Hospice",
    subPages: [
      {
        path: "/preparation-checklist",
        component: Activity9,
        title: "Preparation Checklist",
      },
    ],
  },
  {
    path: "/care-plan",
    component: PrivateSummary,
    title: "My HoPE Care Plan",
    visitedOverride: true,
  },
];

// convert pages into a flat array as the default export
export default structuredPages.reduce((flattenedPages, page) => {
  flattenedPages.push(page);
  if (page.subPages) flattenedPages.push(...page.subPages);
  return flattenedPages;
}, []);
