import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Breakpoint } from "react-socks";

const questionOptions = [
  "How do I talk with my family about hospice?",
  "How will hospice manage my symptoms?",
  "What equipment and supplies will hospice provide?",
  "How often will the hospice team visit?",
  "Will I need to cancel upcoming appointments?",
  "What if my symptoms can't be managed by the hospice team?",
  "Who should I call if I have an emergency?",
  "Will my hospice services change as my condition changes?",
];

export default ({ userData, updateUserData }) => {
  const { activity8 = {} } = userData;
  const { myQuestions = [] } = activity8;

  let mediumUpCol1Array = [];
  let mediumUpCol2Array = [];

  for (let i = 0; i < Math.round(questionOptions.length / 2); i++) {
    mediumUpCol1Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={myQuestions.indexOf(questionOptions[i]) !== -1}
          className="mb-3"
          id="medUp-option-button"
          onClick={() => {
            let myNewQuestions = [];
            if (myQuestions.indexOf(questionOptions[i]) === -1) {
              myNewQuestions[i] = questionOptions[i];
              updateUserData({
                activity8: {
                  myQuestions: myNewQuestions,
                },
              });
            } else {
              myNewQuestions[i] = null;
              updateUserData({
                activity8: {
                  myQuestions: myNewQuestions,
                },
              });
            }
          }}
        >
          {questionOptions[i]}
        </Button>
      </Row>
    );
  }

  for (
    let i = Math.round(questionOptions.length / 2);
    i < questionOptions.length;
    i++
  ) {
    mediumUpCol2Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={myQuestions.indexOf(questionOptions[i]) !== -1}
          className="mb-3"
          id="medUp-option-button"
          onClick={() => {
            let myNewQuestions = [];
            if (myQuestions.indexOf(questionOptions[i]) === -1) {
              myNewQuestions[i] = questionOptions[i];
              updateUserData({
                activity8: {
                  myQuestions: myNewQuestions,
                },
              });
            } else {
              myNewQuestions[i] = null;
              updateUserData({
                activity8: {
                  myQuestions: myNewQuestions,
                },
              });
            }
          }}
        >
          {questionOptions[i]}
        </Button>
      </Row>
    );
  }

  return (
    <>
      <div id="question-container">
        <h6>
          You may still have questions you would like to ask the hospice team.
          Below are some common questions that you can add to your HoPE Care
          Plan.
        </h6>
      </div>
      <br />
      <br />
      <Breakpoint medium up>
        <div id="title-div">
          <h4>What questions do you have for the hospice team?</h4>
          <h5>(select all that apply)</h5>
        </div>
      </Breakpoint>
      <Breakpoint small down>
        <h4>What questions do you have for the hospice team?</h4>
        <h5>(select all that apply)</h5>
      </Breakpoint>
      <br />
      <Breakpoint small down>
        {questionOptions.map((thing, i) => (
          <Row key={i}>
            <Button
              block
              variant="light"
              active={myQuestions.indexOf(thing) !== -1}
              className="mb-3"
              id="option-button"
              onClick={() => {
                let myNewQuestions = [];
                if (myQuestions.indexOf(thing) === -1) {
                  myNewQuestions[i] = thing;
                  updateUserData({
                    activity8: {
                      myQuestions: myNewQuestions,
                    },
                  });
                } else {
                  myNewQuestions[i] = null;
                  updateUserData({
                    activity8: {
                      myQuestions: myNewQuestions,
                    },
                  });
                }
              }}
            >
              {thing}
            </Button>
          </Row>
        ))}
      </Breakpoint>
      <Breakpoint medium up>
        <Row>
          <Col className="mr-5">{mediumUpCol1Array}</Col>
          <Col>{mediumUpCol2Array}</Col>
        </Row>
      </Breakpoint>
    </>
  );
};
