import React, { useState, useEffect } from "react";
import {
  Table,
  Card,
  CardDeck,
  DropdownButton,
  Dropdown,
  Button,
  Nav,
  Modal,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Breakpoint } from "react-socks";
import { sendCarePlanLinkEmail, sendReminderEmail } from "services/email";
import { ReactComponent as BannerLogo } from "HOPE_logo2.svg";
export default ({ userData, readOnly = true }) => {
  const {
    activity1 = { myCareLocation: null },
    activity2 = { myCareFocuses: [] },
    activity2b = { mySymptoms: [] },
    activity3 = {
      myCareNeeds: [],
    },
    activity4 = { myHopes: [] },
    activity5 = { myWorries: [] },
    activity6 = { myPotentialHospiceServices: [] },
    activity7 = {
      myCurrentServices: [],
    },
    activity7b = {
      myCurrentMedEquipUsage: null,
    },
    activity7c = {
      myCurrentLiving: null,
    },
    activity8 = { myQuestions: [] },
    activity9 = {
      caregiver: null,
      contacts: null,
      insurance: null,
      medsEquip: null,
      advDirec: null,
      providers: null,
    },
    passcode,
  } = userData;

  const [email, setEmail] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleSendEmail = (e) => {
    e.preventDefault();
    sendCarePlanLinkEmail(email);
    handleClose();
  };

  const handleSendReminderEmail = (e) => {
    e.preventDefault();
    sendReminderEmail(email, passcode); // this is an example of sending a reminder email
    handleClose2();
  };

  const [complete, setComplete] = useState(true);
  const handleCloseComplete = () => setComplete(false);

  return (
    <>
      {!readOnly && (
        <Modal show={complete} onHide={handleCloseComplete} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>Your HoPE Care Plan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Thank you for completing HoPE. Here is your HoPE Care Plan. Review
              your answers, and make sure it is complete.
            </p>
            <p>At the top of this page, you will find buttons to:</p>
            <ul>
              <li>
                Share your HoPE Care Plan with family or your clinical team.
              </li>
              <li>
                Email the return code to yourself to return to the HoPE app in
                the future.
              </li>
              <li>
                Visit the HoPE Resource Library for more information about
                hospice.
              </li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseComplete}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Breakpoint large up>
        <BannerLogo id="summary-print-logo" width={120} height={120} />
        <Row className="pb-2">
          <Col>
            <h4 id="summary-page-title-phone" className="mr-3">
              My HoPE Care Plan
            </h4>
          </Col>
          <Col>
            {!readOnly && (
              <Button
                id="email-button"
                className="noPrint"
                size="sm"
                onClick={handleShow}
              >
                Email My HoPE Care Plan
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <h5 id="summary-code-phone">{`My code: ${passcode}`}</h5>
          </Col>
          <Col>
            {!readOnly && (
              <Button
                id="summary-button"
                size="sm"
                variant="primary"
                className="noPrint"
                onClick={() => window.print()}
              >
                Print My HoPE Care Plan
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {!readOnly && (
              <Button
                id="summary-button"
                className="mt-3 noPrint"
                size="sm"
                onClick={handleShow2}
              >
                Email My HoPE Code
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {!readOnly && (
              <LinkContainer
                id="summary-button"
                className="mt-3 noPrint"
                to="resource-library"
              >
                <Button size="sm">{"Visit Resource Library"}</Button>
              </LinkContainer>
            )}
          </Col>
        </Row>
        <br />
      </Breakpoint>
      <Breakpoint medium only>
        <Row className="pb-2">
          <Col>
            <h4 id="summary-page-title-phone" className="mr-3">
              My HoPE Care Plan
            </h4>
          </Col>
          <Col>
            {!readOnly && (
              <Button id="email-button" size="sm" onClick={handleShow}>
                Email My HoPE Care Plan
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <h5
              id="summary-code-phone"
              className="mr-3"
            >{`My code: ${passcode}`}</h5>
          </Col>
          <Col>
            {!readOnly && (
              <Button
                id="summary-button"
                className="mt-3"
                size="sm"
                onClick={handleShow2}
              >
                Email My HoPE Code
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {!readOnly && (
              <LinkContainer
                id="summary-button"
                className="mt-3"
                to="resource-library"
              >
                <Button size="sm">{"Visit Resource Library"}</Button>
              </LinkContainer>
            )}
          </Col>
        </Row>
        <br />
      </Breakpoint>
      <Breakpoint small down>
        <div className="align-items-center">
          <h5 id="summary-page-title-phone" className="mr-3">
            My HoPE Care Plan
          </h5>
          {!readOnly && (
            <Button id="email-button-phone" size="sm" onClick={handleShow}>
              Email My HoPE Care Plan
            </Button>
          )}
        </div>
        <br />
        {!readOnly && (
          <div className="align-items-center" id="summary-info-phone">
            <h6
              id="summary-code-phone"
              className="mr-3"
            >{`My code: ${passcode}`}</h6>
            <Button id="summary-button-phone" size="sm" onClick={handleShow2}>
              Email My HoPE Code
            </Button>
          </div>
        )}
        <br />
        <Row>
          <Col>
            {!readOnly && (
              <LinkContainer to="resource-library">
                <Button size="sm">{"Visit Resource Library"}</Button>
              </LinkContainer>
            )}
          </Col>
        </Row>
      </Breakpoint>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Email my HoPE Care Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You can email the HoPE Care Plan to yourself, caregivers or loved
            ones, and members of the clinical team.
          </p>
          <Form onSubmit={handleSendEmail}>
            <Form.Group>
              <Form.Label>Email address:</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              !email.match(
                /[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*/
              )
            }
            variant="primary"
            onClick={handleSendEmail}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show2}
        onHide={handleClose2}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Remind Me My HoPE Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            To access HoPE in the future, you'll need your code. If it will help
            you remember, you can email your code to yourself.
          </p>
          <Form onSubmit={handleSendReminderEmail}>
            <Form.Group>
              <Form.Label>Your email:</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              !email.match(
                /[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*/
              )
            }
            variant="primary"
            onClick={handleSendReminderEmail}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
      {!readOnly && <br />}
      <CardDeck>
        <Card>
          <Card.Header className="card-header-text" as="h5">
            {!readOnly && (
              <DropdownButton
                alignRight
                id="nav-from-summary"
                size="sm"
                title="Edit"
              >
                <Dropdown.Item as="button">
                  <LinkContainer to="/care-location">
                    <Nav.Link>{"My care location"}</Nav.Link>
                  </LinkContainer>
                </Dropdown.Item>
                <Dropdown.Item as="button">
                  <LinkContainer to="/focus-of-care">
                    <Nav.Link>{"My focus of care"}</Nav.Link>
                  </LinkContainer>
                </Dropdown.Item>
                <Dropdown.Item as="button">
                  <LinkContainer to="/hospice-team-support">
                    <Nav.Link>{"How hospice can help"}</Nav.Link>
                  </LinkContainer>
                </Dropdown.Item>
              </DropdownButton>
            )}
            My Care Preferences
          </Card.Header>
          <Card.Body>
            <Card.Subtitle id="summary-subheader" className="mb-2">
              Do I prefer care at home?
            </Card.Subtitle>
            <Card.Text>
              {activity1.myCareLocation === null
                ? ""
                : ` ${activity1.myCareLocation}`}
            </Card.Text>
            <Card.Subtitle id="summary-subheader" className="mb-2">
              Things I want my care to focus on
            </Card.Subtitle>
            <Card.Text>
              {activity2.myCareFocuses.map((thing, i) =>
                thing === null ? (
                  ""
                ) : (
                  <ul id="summary-ul">
                    {" "}
                    <li id="summary-li">{`${thing}`}</li>{" "}
                  </ul>
                )
              )}
            </Card.Text>
            <Card.Subtitle id="summary-subheader" className="mb-2">
              How hospice can help
            </Card.Subtitle>
            <Card.Text>
              {activity3.myCareNeeds.map((thing, i) =>
                thing === null ? (
                  ""
                ) : (
                  <ul id="summary-ul">
                    {" "}
                    <li id="summary-li">{`${thing}`}</li>{" "}
                  </ul>
                )
              )}
            </Card.Text>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header className="card-header-text" as="h5">
            {!readOnly && (
              <DropdownButton
                alignRight
                id="nav-from-summary"
                size="sm"
                title="Edit"
              >
                <Dropdown.Item as="button">
                  <LinkContainer to="/your-hopes">
                    <Nav.Link>{"My hopes"}</Nav.Link>
                  </LinkContainer>
                </Dropdown.Item>
                <Dropdown.Item as="button">
                  <LinkContainer to="/your-worries">
                    <Nav.Link>{"My worries"}</Nav.Link>
                  </LinkContainer>
                </Dropdown.Item>
                <Dropdown.Item as="button">
                  <LinkContainer to="/symptoms">
                    <Nav.Link>{"My symptoms"}</Nav.Link>
                  </LinkContainer>
                </Dropdown.Item>
              </DropdownButton>
            )}
            My Hopes, Worries, and Symptoms
          </Card.Header>
          <Card.Body>
            <Card.Subtitle id="summary-subheader" className="mb-2">
              My hopes
            </Card.Subtitle>
            <Card.Text>
              {activity4.myHopes.map((thing, i) =>
                thing === null ? (
                  ""
                ) : (
                  <ul id="summary-ul">
                    {" "}
                    <li id="summary-li">{`${thing}`}</li>{" "}
                  </ul>
                )
              )}
            </Card.Text>
            <Card.Subtitle id="summary-subheader" className="mb-2">
              My worries
            </Card.Subtitle>
            <Card.Text>
              {activity5.myWorries.map((thing, i) =>
                thing === null ? (
                  ""
                ) : (
                  <ul id="summary-ul">
                    {" "}
                    <li id="summary-li">{`${thing}`}</li>{" "}
                  </ul>
                )
              )}
            </Card.Text>
            <Card.Subtitle id="summary-subheader" className="mb-2">
              My symptoms
            </Card.Subtitle>
            <Card.Text>
              {activity2b.mySymptoms.map((thing, i) =>
                thing === null ? (
                  ""
                ) : (
                  <ul id="summary-ul">
                    {" "}
                    <li id="summary-li">{`${thing}`}</li>{" "}
                  </ul>
                )
              )}
            </Card.Text>
          </Card.Body>
        </Card>
      </CardDeck>
      <br />
      <CardDeck>
        <Card>
          <Card.Header className="card-header-text" as="h5">
            {!readOnly && (
              <LinkContainer id="nav-from-summary" to="most-helpful-services">
                <Button size="sm">{"Edit"}</Button>
              </LinkContainer>
            )}
            Hospice Services I Might Find Helpful
          </Card.Header>
          <Card.Body>
            <Card.Text>
              {activity6.myPotentialHospiceServices.map((thing, i) =>
                thing === null ? (
                  ""
                ) : (
                  <ul id="summary-ul">
                    {" "}
                    <li id="summary-li">{`${thing}`}</li>{" "}
                  </ul>
                )
              )}
            </Card.Text>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header className="card-header-text" as="h5">
            {!readOnly && (
              <DropdownButton
                alignRight
                id="nav-from-summary"
                size="sm"
                title="Edit"
              >
                <Dropdown.Item as="button">
                  <LinkContainer to="/currently-received-services">
                    <Nav.Link>{"My current services"}</Nav.Link>
                  </LinkContainer>
                </Dropdown.Item>
                <Dropdown.Item as="button">
                  <LinkContainer to="/medical-equipment">
                    <Nav.Link>{"My medical equipment"}</Nav.Link>
                  </LinkContainer>
                </Dropdown.Item>
                <Dropdown.Item as="button">
                  <LinkContainer to="/living-situation">
                    <Nav.Link>{"My living situation"}</Nav.Link>
                  </LinkContainer>
                </Dropdown.Item>
              </DropdownButton>
            )}
            My Current Services
          </Card.Header>
          <Card.Body>
            <Card.Subtitle id="summary-subheader" className="mb-2">
              My current services
            </Card.Subtitle>
            <Card.Text>
              {activity7.myCurrentServices.map((thing, i) =>
                thing === null ? (
                  ""
                ) : (
                  <ul id="summary-ul">
                    {" "}
                    <li id="summary-li">{`${thing}`}</li>{" "}
                  </ul>
                )
              )}
            </Card.Text>
            <Card.Subtitle id="summary-subheader" className="mb-2">
              Am I using medical equipment?
            </Card.Subtitle>
            <Card.Text>
              {activity7b.myCurrentMedEquipUsage !== null
                ? ` ${activity7b.myCurrentMedEquipUsage}`
                : ""}
            </Card.Text>
            <Card.Subtitle id="summary-subheader" className="mb-2">
              Where am I living now?
            </Card.Subtitle>
            <Card.Text>
              {activity7c.myCurrentLiving === null
                ? ""
                : activity7c.myCurrentLiving}
            </Card.Text>
          </Card.Body>
        </Card>
      </CardDeck>
      <br />
      <CardDeck>
        <Card>
          <Card.Header className="card-header-text" as="h5">
            {!readOnly && (
              <LinkContainer
                id="nav-from-summary"
                to="questions-for-your-care-team"
              >
                <Button size="sm">{"Edit"}</Button>
              </LinkContainer>
            )}
            My Questions for Hospice
          </Card.Header>
          <Card.Body>
            <Card.Text>
              {activity8.myQuestions.map((thing, i) =>
                thing === null ? (
                  ""
                ) : (
                  <ul id="summary-ul">
                    {" "}
                    <li id="summary-li">{`${thing}`}</li>{" "}
                  </ul>
                )
              )}
            </Card.Text>
          </Card.Body>
        </Card>
      </CardDeck>
      <br />
      <CardDeck>
        <Card>
          <Card.Header className="card-header-text" as="h5">
            {!readOnly && (
              <LinkContainer id="nav-from-summary" to="preparation-checklist">
                <Button size="sm">{"Edit"}</Button>
              </LinkContainer>
            )}
            My Checklist for My Hospice Visit
          </Card.Header>
          <Card.Body>
            <div className="table-responsive">
              <Breakpoint medium up>
                <Table>
                  <thead>
                    <tr>
                      <th />
                      <th>Name of caregiver(s)</th>
                      <th>Contacts</th>
                      <th>Insurance</th>
                      <th>Medications, Equipment</th>
                      <th>Advance Directive</th>
                      <th>List of Providers</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Status</th>
                      <td>{activity9.caregiver ? activity9.caregiver : ""}</td>
                      <td>{activity9.contacts ? activity9.contacts : ""}</td>
                      <td>{activity9.insurance ? activity9.insurance : ""}</td>
                      <td>{activity9.medsEquip ? activity9.medsEquip : ""}</td>
                      <td>{activity9.advDirec ? activity9.advDirec : ""}</td>
                      <td>{activity9.providers ? activity9.providers : ""}</td>
                    </tr>
                  </tbody>
                </Table>
              </Breakpoint>
              <Breakpoint small down>
                <Table>
                  <thead>
                    <tr>
                      <th>Name of caregiver(s)</th>
                      <th>Contacts</th>
                      <th>Insurance</th>
                      <th>Medications, Equipment</th>
                      <th>Advance Directive</th>
                      <th>List of Providers</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{activity9.caregiver ? activity9.caregiver : ""}</td>
                      <td>{activity9.contacts ? activity9.contacts : ""}</td>
                      <td>{activity9.insurance ? activity9.insurance : ""}</td>
                      <td>{activity9.medsEquip ? activity9.medsEquip : ""}</td>
                      <td>{activity9.advDirec ? activity9.advDirec : ""}</td>
                      <td>{activity9.providers ? activity9.providers : ""}</td>
                    </tr>
                  </tbody>
                </Table>
              </Breakpoint>
            </div>
          </Card.Body>
        </Card>
      </CardDeck>
    </>
  );
};

/*
TEXT AT BOTTOM - PROVISIONAL:
{!readOnly && <br />}
{!readOnly && (
  <h6>
    Thank you for completing the HoPE app and creating your HoPE Care
    Plan. You can click Next to view the Resource Library or Logout to
    exit the app.
  </h6>
)}
*/
