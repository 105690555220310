import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import { useCurrentBreakpointName } from "react-socks";
import { sendPasswordResetEmail } from "services/email";
import { SUPERUSER_TYPES } from "services/admin";
import UserContext from "context/UserContext";

const AdminLoginForm = ({ history, loading, setLoading, loginSuperuser }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [showResetMessage, setShowResetMessage] = useState(false);

  const breakpoint = useCurrentBreakpointName();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    const userData = await loginSuperuser(
      email,
      password,
      SUPERUSER_TYPES.ADMIN
    );

    if (userData) {
      setLoading(false);
      setEmail("");
      setPassword("");

      const { type } = userData;

      const redirectUrl = type === SUPERUSER_TYPES.ADMIN ? "/admin/home" : "/admin/liaison";

      history.push(redirectUrl);
    } else {
      setLoading(false);
      setPassword("");
      setError("Invalid code, please try again.");
    }
  };

  const handlePasswordReset = async (email) => {
    setError(null);
    setLoading(true);
    setShowResetMessage(false);

    await sendPasswordResetEmail(email);
    setLoading(false);
    setShowResetMessage(true);
  }

  return (
    <>
      <div id={(breakpoint === 'xsmall' || breakpoint === 'small') ? 'landing-box' : 'landing-box-big'}>
        <h3>Liaison/Admin Login</h3>
        <Form className="pt-4" noValidate onSubmit={handleSubmit}>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Email"
              disabled={loading}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Control
              type="password"
              placeholder="Password"
              disabled={loading}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              disabled={email.length === 0 || password.length === 0 || loading}
            >
              {loading && password.length > 0 ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Confirming...
                </>
              ) : (
                  <>Continue</>
                )}
            </Button>
            <Button
              className="float-right"
              variant="link"
              disabled={email.length === 0 || loading}
              onClick={() => handlePasswordReset(email)}
            >
              Reset Password
            </Button>
          </Form.Group>
        </Form>
        {showResetMessage && (
          <>
            {`Thank you. If a user with that email address exists, a password reset email has been sent to their address.`}
          </>
        )}
      </div>
    </>
  );
};

export default withRouter(({ history }) => {
  const [loading, setLoading] = useState(false);

  return (
    <UserContext.Consumer>
      {({ createUser, loginSuperuser }) => (
        <>
          <AdminLoginForm
            history={history}
            loading={loading}
            setLoading={setLoading}
            loginSuperuser={loginSuperuser}
          />
        </>
      )}
    </UserContext.Consumer>
  );
});
