import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Breakpoint } from "react-socks";

const hospiceServiceOptions = [
  "Managing symptoms like pain or shortness of breath",
  "Ordering medicines or equipment (e.g., oxygen, hospital bed)",
  "Assisting with my personal care needs like bathing",
  "Helping with paperwork like a healthcare power of attorney",
  "Having companionship through visits with a hospice volunteer",
  "Receiving spiritual support",
  "Making sure my loved ones receive support",
  "Receiving emotional support",
  "Learning about community resources",
  "Something else"
];

export default ({ userData, updateUserData }) => {
  const { activity6 = {} } = userData;
  const { myPotentialHospiceServices = [] } = activity6;

  let mediumUpCol1Array = [];
  let mediumUpCol2Array = [];

  for (let i = 0; i < Math.round(hospiceServiceOptions.length / 2); i++) {
    mediumUpCol1Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={
            myPotentialHospiceServices.indexOf(hospiceServiceOptions[i]) !== -1
          }
          className="mb-3"
          id="medUp-option-button"
          onClick={() => {
            let myNewPotentialHospiceServices = [];
            if (
              myPotentialHospiceServices.indexOf(hospiceServiceOptions[i]) ===
              -1
            ) {
              myNewPotentialHospiceServices[i] = hospiceServiceOptions[i];
              updateUserData({
                activity6: {
                  myPotentialHospiceServices: myNewPotentialHospiceServices
                }
              });
            } else {
              myNewPotentialHospiceServices[i] = null;
              updateUserData({
                activity6: {
                  myPotentialHospiceServices: myNewPotentialHospiceServices
                }
              });
            }
          }}
        >
          {hospiceServiceOptions[i]}
        </Button>
      </Row>
    );
  }

  for (
    let i = Math.round(hospiceServiceOptions.length / 2);
    i < hospiceServiceOptions.length;
    i++
  ) {
    mediumUpCol2Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={
            myPotentialHospiceServices.indexOf(hospiceServiceOptions[i]) !== -1
          }
          className="mb-3"
          id="medUp-option-button"
          onClick={() => {
            let myNewPotentialHospiceServices = [];
            if (
              myPotentialHospiceServices.indexOf(hospiceServiceOptions[i]) ===
              -1
            ) {
              myNewPotentialHospiceServices[i] = hospiceServiceOptions[i];
              updateUserData({
                activity6: {
                  myPotentialHospiceServices: myNewPotentialHospiceServices
                }
              });
            } else {
              myNewPotentialHospiceServices[i] = null;
              updateUserData({
                activity6: {
                  myPotentialHospiceServices: myNewPotentialHospiceServices
                }
              });
            }
          }}
        >
          {hospiceServiceOptions[i]}
        </Button>
      </Row>
    );
  }

  return (
    <>
      <div id="question-container">
        <h6>
          The previous video described the members of a hospice team and the
          services each member provides. In the activity below, pick the hospice
          services that would be the most helpful to you.
        </h6>
      </div>
      <br />
      <br />
      <Breakpoint medium up>
        <div id="title-div">
          <h4>What hospice services would you find most helpful?</h4>
          <h5>(select all that apply)</h5>
        </div>
      </Breakpoint>
      <Breakpoint small down>
        <h4>What hospice services would you find most helpful?</h4>
        <h5>(select all that apply)</h5>
      </Breakpoint>
      <br />
      <Breakpoint small down>
        {hospiceServiceOptions.map((thing, i) => (
          <Row key={i}>
            <Button
              block
              variant="light"
              active={myPotentialHospiceServices.indexOf(thing) !== -1}
              className="mb-3"
              id="option-button"
              onClick={() => {
                let myNewPotentialHospiceServices = [];
                if (myPotentialHospiceServices.indexOf(thing) === -1) {
                  myNewPotentialHospiceServices[i] = thing;
                  updateUserData({
                    activity6: {
                      myPotentialHospiceServices: myNewPotentialHospiceServices
                    }
                  });
                } else {
                  myNewPotentialHospiceServices[i] = null;
                  updateUserData({
                    activity6: {
                      myPotentialHospiceServices: myNewPotentialHospiceServices
                    }
                  });
                }
              }}
            >
              {thing}
            </Button>
          </Row>
        ))}
      </Breakpoint>
      <Breakpoint medium up>
        <Row>
          <Col className="mr-5">{mediumUpCol1Array}</Col>
          <Col>{mediumUpCol2Array}</Col>
        </Row>
      </Breakpoint>
    </>
  );
};
