import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { Breakpoint } from "react-socks";
import { GoKey } from "react-icons/go";
import { IoMdVolumeHigh } from "react-icons/io";

export default class Page0 extends Component {
  render() {
    return (
      <>
        <h4>Getting Started with HoPE</h4>
        <p>
          Thank you for using HoPE. Here are some things to help you get
          started.
        </p>
        <Breakpoint medium up>
          <Row>
            <GoKey color="#9ec087" size="8em" id="key-image" />
            <Col>
              <div id="key-div">
                <h5>Your Unique Code</h5>
                <p>
                  For your privacy, HoPE does not collect any identifiable
                  information (e.g., name, email). Instead, HoPE will generate a
                  4-digit code (example: 1234) for you to return to the website
                  and access your saved responses. This unique code will always
                  be visible in the upper right corner of the page (or in the
                  menu on mobile devices). On a later page, you can email the
                  code to yourself.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <IoMdVolumeHigh color="#9ec087" size="8em" id="key-image" />
            <Col>
              <div id="key-div">
                <h5>Is Your Volume On and Up?</h5>
                <p>
                  While using HoPE, you will watch several videos. If you play a
                  video, but cannot hear anything, turn up your device’s volume.
                  If you prefer, you can watch the video with subtitles.
                </p>
              </div>
            </Col>
          </Row>
        </Breakpoint>

        <Breakpoint small down>
          <h5>Your Unique Code</h5>
          <GoKey color="#9ec087" size="4.5em" id="key-image" className="mr-1" />
          <p>
            For your privacy, HoPE does not collect any identifiable information
            (e.g., name, email). Instead, HoPE will generate a 4-digit code
            (example: 1234) for you to return to the website and access your
            saved responses. This unique code will always be visible in the
            upper right corner of the page (or in the menu on mobile devices).
            On a later page, you can email the code to yourself.
          </p>
          <h5>Is Your Volume On and Up?</h5>
          <IoMdVolumeHigh
            color="#9ec087"
            size="4.5em"
            id="key-image"
            className="mr-1"
          />
          <p>
            While using HoPE, you will watch several videos. If you play a
            video, but cannot hear anything, turn up your device’s volume. If
            you prefer, you can watch the video with subtitles.
          </p>
        </Breakpoint>
      </>
    );
  }
}
