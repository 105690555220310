import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Breakpoint } from "react-socks";

const worryOptions = [
  "Symptoms getting worse",
  "Losing my independence",
  "Difficulty with eating or drinking",
  "Difficulty getting out of bed or moving around",
  "Giving the impression that I am giving up",
  "How my family will cope as things change",
  "Losing connection with my current care team",
  "Something else",
];

export default ({ userData, updateUserData }) => {
  const { activity5 = {} } = userData;
  const { myWorries = [] } = activity5;

  let mediumUpCol1Array = [];
  let mediumUpCol2Array = [];

  for (let i = 0; i < Math.round(worryOptions.length / 2); i++) {
    mediumUpCol1Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={myWorries.indexOf(worryOptions[i]) !== -1}
          className="mb-3"
          id="medUp-option-button"
          onClick={() => {
            let myNewWorries = [];
            if (myWorries.indexOf(worryOptions[i]) === -1) {
              myNewWorries[i] = worryOptions[i];
              updateUserData({
                activity5: {
                  myWorries: myNewWorries,
                },
              });
            } else {
              myNewWorries[i] = null;
              updateUserData({
                activity5: {
                  myWorries: myNewWorries,
                },
              });
            }
          }}
        >
          {worryOptions[i]}
        </Button>
      </Row>
    );
  }

  for (
    let i = Math.round(worryOptions.length / 2);
    i < worryOptions.length;
    i++
  ) {
    mediumUpCol2Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={myWorries.indexOf(worryOptions[i]) !== -1}
          className="mb-3"
          id="medUp-option-button"
          onClick={() => {
            let myNewWorries = [];
            if (myWorries.indexOf(worryOptions[i]) === -1) {
              myNewWorries[i] = worryOptions[i];
              updateUserData({
                activity5: {
                  myWorries: myNewWorries,
                },
              });
            } else {
              myNewWorries[i] = null;
              updateUserData({
                activity5: {
                  myWorries: myNewWorries,
                },
              });
            }
          }}
        >
          {worryOptions[i]}
        </Button>
      </Row>
    );
  }

  return (
    <>
      <div id="question-container">
        <h6>
          In the activity below, choose the things that make you worry (or the
          things that would cause a “bad day” for you).
        </h6>
      </div>
      <br />
      <br />
      <Breakpoint medium up>
        <div id="title-div">
          <h4>As you think about the future, what worries you?</h4>
          <h5>(select all that apply)</h5>
        </div>
      </Breakpoint>
      <Breakpoint small down>
        <h4>As you think about the future, what worries you?</h4>
        <h5>(select all that apply)</h5>
      </Breakpoint>
      <br />
      <Breakpoint small down>
        {worryOptions.map((thing, i) => (
          <Row key={i}>
            <Button
              block
              variant="light"
              active={myWorries.indexOf(thing) !== -1}
              className="mb-3"
              id="option-button"
              onClick={() => {
                let myNewWorries = [];
                if (myWorries.indexOf(thing) === -1) {
                  myNewWorries[i] = thing;
                  updateUserData({
                    activity5: {
                      myWorries: myNewWorries,
                    },
                  });
                } else {
                  myNewWorries[i] = null;
                  updateUserData({
                    activity5: {
                      myWorries: myNewWorries,
                    },
                  });
                }
              }}
            >
              {thing}
            </Button>
          </Row>
        ))}
      </Breakpoint>
      <Breakpoint medium up>
        <Row>
          <Col className="mr-5">{mediumUpCol1Array}</Col>
          <Col>{mediumUpCol2Array}</Col>
        </Row>
      </Breakpoint>
    </>
  );
};
