import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Breakpoint } from "react-socks";

const careFocusOptions = [
  "Managing my symptoms",
  "Continuing to do the things I enjoy",
  "Getting support for my family",
  "Being independent for as long as possible",
  "Living for as long as possible",
  "Spending time with my family",
  "Putting my affairs in order",
  "Helping me stay in my own home",
  "Feeling at peace",
  "Something else"
];

export default ({ userData, updateUserData }) => {
  const { activity2 = {} } = userData;
  const { myCareFocuses = [] } = activity2;

  let mediumUpCol1Array = [];
  let mediumUpCol2Array = [];

  for (let i = 0; i < Math.round(careFocusOptions.length / 2); i++) {
    mediumUpCol1Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={myCareFocuses.indexOf(careFocusOptions[i]) !== -1}
          className="mb-3"
          id="medUp-option-button"
          onClick={() => {
            let myNewCareFocuses = [];
            if (myCareFocuses.indexOf(careFocusOptions[i]) === -1) {
              myNewCareFocuses[i] = careFocusOptions[i];
              updateUserData({
                activity2: {
                  myCareFocuses: myNewCareFocuses
                }
              });
            } else {
              myNewCareFocuses[i] = null;
              updateUserData({
                activity2: {
                  myCareFocuses: myNewCareFocuses
                }
              });
            }
          }}
        >
          {careFocusOptions[i]}
        </Button>
      </Row>
    );
  }

  for (
    let i = Math.round(careFocusOptions.length / 2);
    i < careFocusOptions.length;
    i++
  ) {
    mediumUpCol2Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={myCareFocuses.indexOf(careFocusOptions[i]) !== -1}
          className="mb-3"
          id="medUp-option-button"
          onClick={() => {
            let myNewCareFocuses = [];
            if (myCareFocuses.indexOf(careFocusOptions[i]) === -1) {
              myNewCareFocuses[i] = careFocusOptions[i];
              updateUserData({
                activity2: {
                  myCareFocuses: myNewCareFocuses
                }
              });
            } else {
              myNewCareFocuses[i] = null;
              updateUserData({
                activity2: {
                  myCareFocuses: myNewCareFocuses
                }
              });
            }
          }}
        >
          {careFocusOptions[i]}
        </Button>
      </Row>
    );
  }

  return (
    <>
      <div id="question-container">
        <h6>
          Choosing what you want your care to focus on is helpful so that
          everyone is on the same page. Elizabeth decided it was important that
          her symptoms were managed so that she could get a good night’s rest.
          In the activity below, pick the things you want your care to focus on.
        </h6>
      </div>
      <br />
      <br />
      <Breakpoint medium up>
        <div id="title-div">
          <h4>What would you like your care to focus on?</h4>
          <h5>(select all that apply)</h5>
        </div>
      </Breakpoint>
      <Breakpoint small down>
        <h4>What would you like your care to focus on?</h4>
        <h5>(select all that apply)</h5>
      </Breakpoint>
      <br />
      <Breakpoint small down>
        {careFocusOptions.map((thing, i) => (
          <Row key={i}>
            <Button
              block
              variant="light"
              active={myCareFocuses.indexOf(thing) !== -1}
              className="mb-3"
              id="option-button"
              onClick={() => {
                let myNewCareFocuses = [];
                if (myCareFocuses.indexOf(thing) === -1) {
                  myNewCareFocuses[i] = thing;
                  updateUserData({
                    activity2: {
                      myCareFocuses: myNewCareFocuses
                    }
                  });
                } else {
                  myNewCareFocuses[i] = null;
                  updateUserData({
                    activity2: {
                      myCareFocuses: myNewCareFocuses
                    }
                  });
                }
              }}
            >
              {thing}
            </Button>
          </Row>
        ))}
      </Breakpoint>
      <Breakpoint medium up>
        <Row>
          <Col className="mr-5">{mediumUpCol1Array}</Col>
          <Col>{mediumUpCol2Array}</Col>
        </Row>
      </Breakpoint>
    </>
  );
};
