import React from "react";
import { withRouter } from "react-router-dom";
import { TiMediaRecordOutline, TiMediaRecord } from "react-icons/ti";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";
import { Breakpoint } from "react-socks";

import UserContext from "context/UserContext";
import { structuredPages } from "config/pageConfig";
import pageConfig from "config/pageConfig";

import styles from "./styles.module.scss";

const PageIndicator = ({ visited, active }) => {
  if (active)
    return (
      <TiMediaRecord className={[styles.indicator, styles.active].join(" ")} />
    );
  if (visited)
    return (
      <TiMediaRecord className={[styles.indicator, styles.visited].join(" ")} />
    );
  return <TiMediaRecordOutline className={styles.indicator} />;
};

const PageTitle = ({
  className,
  title,
  visited,
  active,
  pathname,
  onClick
}) => {
  if (visited && !active) {
    return (
      <LinkContainer to={`${pathname}`}>
        <div className={[className, styles.link].join(" ")} onClick={onClick}>
          <PageIndicator visited={visited} active={active} />
          {title}
        </div>
      </LinkContainer>
    );
  }

  return (
    <div className={className}>
      <PageIndicator visited={visited} active={active} />
      {title}
    </div>
  );
};

const SidebarEntry = ({
  page,
  location,
  userData,
  updatePathAndCommitData,
  visitedOverride = false
}) => {
  const activePage =
    location.pathname === page.path ||
    (page.subPages && page.subPages.findIndex(subPage => subPage.path === location.pathname) > -1);
  const visitedPaths =
    userData && userData.visitedPaths ? userData.visitedPaths : {};
  return (
    <div
      className={
        activePage
          ? [styles.sidebarEntry, styles.active].join(" ")
          : styles.sidebarEntry
      }
    >
      <PageTitle
        className={styles.pageTitle}
        title={page.title}
        visited={
          visitedOverride || page.visitedOverride || visitedPaths[page.path]
        }
        active={page.path === location.pathname}
        pathname={page.path}
        onClick={() => updatePathAndCommitData(page.path)}
      />
      {page.subPages && (
        <div className={styles.subPageContainer}>
          {page.subPages.map(subPage => (
            <PageTitle
              key={subPage.path}
              className={styles.subPageTitle}
              title={subPage.title}
              visited={
                visitedOverride ||
                page.visitedOverride ||
                visitedPaths[subPage.path]
              }
              active={subPage.path === location.pathname}
              pathname={subPage.path}
              onClick={() => updatePathAndCommitData(subPage.path)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Sidebar = ({
  location,
  expanded,
  userData,
  updateUserData,
  commitUserData
}) => {
  let currentPageIndex = pageConfig.findIndex(
    page => page.path === location.pathname
  );

  const updatePathAndCommitData = async path => {
    if (pageConfig.find(page => page.path === path)) {
      const visitedPaths = userData.visitedPaths || {};
      visitedPaths[path] = true;
      await updateUserData({ path, visitedPaths });
      await commitUserData();
    }
  };

  if (!userData || currentPageIndex === -1) return null;
  return (
    <div id="sidebar" className={styles.sidebarContainer}>
      <div
        id="sidebar-content"
        className={
          expanded
            ? styles.sidebar
            : [styles.sidebar, styles.collapsed].join(" ")
        }
      >
        {structuredPages.map(page => (
          <SidebarEntry
            id="sidebar-entry"
            key={page.path}
            page={page}
            location={location}
            userData={userData}
            updatePathAndCommitData={updatePathAndCommitData}
          />
        ))}
        <Breakpoint small down>
          <div className={styles.divider} />
          <SidebarEntry
            page={{ path: "/about", title: "About" }}
            location={location}
            userData={userData}
            updatePathAndCommitData={updatePathAndCommitData}
            visitedOverride={true}
          />
          <SidebarEntry
            page={{ path: "/terms", title: "Terms" }}
            location={location}
            userData={userData}
            updatePathAndCommitData={updatePathAndCommitData}
            visitedOverride={true}
          />
          <div>
            <p className="ml-3">Logged in as {userData.passcode}</p>
          </div>
          <div>
            <UserContext.Consumer>
              {({ logoutUser }) => (
                <Button
                  variant="secondary"
                  className="ml-3"
                  onClick={logoutUser}
                >
                  Logout
                </Button>
              )}
            </UserContext.Consumer>
          </div>
        </Breakpoint>
      </div>
    </div>
  );
};

export default withRouter(({ location, expanded }) => (
  <UserContext.Consumer>
    {({ userData, updateUserData, commitUserData }) => (
      <Sidebar
        location={location}
        userData={userData}
        updateUserData={updateUserData}
        commitUserData={commitUserData}
        expanded={expanded}
      />
    )}
  </UserContext.Consumer>
));
