import { auth } from "services/firebase";

const baseUrl = `https://myhopeplan.org`;

const sendEmail = async (targetEmail, emailSubject, emailContent) => {
  const url = `https://php.pcforme.org/sendEmail.php?to_email=${targetEmail}&subject=${emailSubject}&content=${emailContent}`;
  const response = await fetch(url);
  return response.ok;
};

export const sendCarePlanLinkEmail = async (targetEmail) => {
  const userId = auth.currentUser.uid;
  const subject = `You have been sent a HoPE Care Plan`;
  const content = `You have been sent a HoPE Care Plan link. To view the Care Plan, please visit ${baseUrl}/summary/${userId}`;
  return sendEmail(targetEmail, subject, content);
};

export const sendReminderEmail = async (targetEmail, passcode) => {
  const userId = auth.currentUser.uid;
  const subject = `Your HoPE Code`;
  const content = `Thank you for using HoPE. Your code to return later is ${passcode}. Please save this code, as you will not be able to return to your session without it. You can access a read-only version of your HoPE Care Plan at any time by visiting ${baseUrl}/summary/${userId}`;
  return sendEmail(targetEmail, subject, content);
};

export const sendPasswordResetEmail = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email, {
      url: `${baseUrl}/admin`
    });
    return true;
  } catch (error) {
    return error;
  }
};