import React from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Nav, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import UserContext from "context/UserContext";
import pageConfig from "config/pageConfig";
import { Breakpoint } from "react-socks";

import { ReactComponent as HeaderLogo } from "HOPE_logo_small.svg";
import { ReactComponent as DropdownImage } from "dropdown.svg";

const Header = ({
  expanded,
  setExpanded,
  isUserAuthenticated,
  userData,
  logoutUser
}) => {
  const location = useLocation();

  let currentPageIndex = pageConfig.findIndex(
    page => page.path === location.pathname
  );

  return (
    <>
      <Breakpoint small down>
        <Navbar id="nav-bar">
          <Nav className="align-items-center">
            <Nav.Item>
              <LinkContainer to="/">
                <Navbar.Brand>
                  <HeaderLogo width={80} height={80} />
                </Navbar.Brand>
              </LinkContainer>
            </Nav.Item>
          </Nav>
          <Nav className="ml-auto">
            {currentPageIndex === -1 && (
              <Nav.Item id="phone-about" className="mr-3">
                <LinkContainer to="/about">
                  <Nav.Link>About</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            )}
            {currentPageIndex === -1 && (
              <Nav.Item id="phone-terms">
                <LinkContainer to="/terms">
                  <Nav.Link>{"Terms"}</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            )}
          </Nav>
          {currentPageIndex !== -1 && (
            <Nav className="ml-auto">
              <Nav.Item>
                <Navbar.Brand
                  id="nav-dropdown-small-down"
                  onClick={() => setExpanded(!expanded)}
                >
                  <DropdownImage width={32} height={32} />
                </Navbar.Brand>
              </Nav.Item>
            </Nav>
          )}
          {isUserAuthenticated && currentPageIndex === -1 && (
            <Nav.Item className="ml-3">
              <Button
                size="sm"
                variant="primary"
                id="dropdown-logout"
                onClick={logoutUser}
              >
                Logout
              </Button>
            </Nav.Item>
          )}
        </Navbar>
      </Breakpoint>

      <Breakpoint medium up>
        <Navbar id="nav-bar">
          <Nav className="align-items-center">
            <Nav.Item>
              <LinkContainer to="/">
                <Navbar.Brand>
                  <HeaderLogo width={108} height={108} />
                </Navbar.Brand>
              </LinkContainer>
            </Nav.Item>
            {currentPageIndex === -1 && (
              <Nav.Item>
                <LinkContainer to="/about">
                  <Nav.Link>About</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            )}
            {currentPageIndex === -1 && (
              <Nav.Item>
                <LinkContainer to="/terms">
                  <Nav.Link>{"Terms"}</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            )}
          </Nav>
          <Nav id="navbar-code-logout" className="ml-auto align-items-center">
            <Nav.Item>
              {isUserAuthenticated && <h5>{userData.passcode}</h5>}
            </Nav.Item>
            <Nav.Item className="ml-3">
              {isUserAuthenticated && (
                <Button size="sm" id="dropdown-logout" onClick={logoutUser}>
                  Logout
                </Button>
              )}
            </Nav.Item>
          </Nav>
        </Navbar>
      </Breakpoint>
    </>
  );
};

export default ({ expanded, setExpanded }) => (
  <UserContext.Consumer>
    {({ isUserAuthenticated, userData, logoutUser }) => (
      <Header
        expanded={expanded}
        setExpanded={setExpanded}
        isUserAuthenticated={isUserAuthenticated}
        userData={userData}
        logoutUser={logoutUser}
      />
    )}
  </UserContext.Consumer>
);
