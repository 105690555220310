// Adapted from https://github.com/TehShrike/is-mergeable-object/blob/master/index.js
const isNonNullObject = (value) => !!value && typeof value === 'object';

const isSpecial = (value) => {
  const stringValue = Object.prototype.toString.call(value);

  return stringValue === '[object RegExp]'
    || stringValue === '[object Date]'
    || isReactElement(value);
};

const canUseSymbol = typeof Symbol === 'function' && Symbol.for;
const REACT_ELEMENT_TYPE = canUseSymbol ? Symbol.for('react.element') : 0xeac7;

const isReactElement = (value) => {
  return value.$$typeof === REACT_ELEMENT_TYPE;
};

export const isMergeableObject = (value) => isNonNullObject(value) && !isSpecial(value);

export const buildCustomIsMergeableObject = (test) => (value) => test(value) && isMergeableObject(value);