import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Button,
  Table,
  ListGroup,
  Row,
  Col,
} from "react-bootstrap";

import { createSuperuser } from "services/admin";
import { db } from "services/firebase";

import styles from "./styles.module.scss";

const OrgEntry = ({ organizationData, openCreateLiaisonModal }) => {
  console.log("organizationData", organizationData);
  const { url, name, id, liaisons } = organizationData;
  return (
    <ListGroup.Item className="my-2">
      <Row className="mb-2 ml-1">
        <h4 className="mr-4">{name}</h4>
        <h4 className={[styles.url, "ml-4"].join(" ")}>{`/${url}`}</h4>
        <Button className="ml-auto" onClick={() => openCreateLiaisonModal(id)}>
          Create Liaison
        </Button>
      </Row>
      {liaisons && liaisons.length > 0 && (
        <Row>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Email</th>
                <th>Set Password Reset</th>
              </tr>
            </thead>
            <tbody>
              {liaisons.map((liaison) => (
                <tr key={liaison.id}>
                  <td>{liaison.email}</td>
                  <td>
                    {liaison.requirePasswordReset
                      ? `Already Set`
                      : `Send Reset`}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      )}
    </ListGroup.Item>
  );
};

const AdminHome = () => {
  const [fetchedOrganizationData, setFetchedOrganizationData] = useState(null);
  const [showCreateLiaisonModal, setShowCreateLiaisonModal] = useState(false);
  const [activeOrganizationId, setActiveOrganizationId] = useState(null);
  const [createLiaisonModalLoading, setCreateLiaisonModalLoading] = useState(
    false
  );
  const [newLiaisonEmail, setNewLiaisonEmail] = useState("");
  const [newLiaisonInitialPassword, setNewLiaisonInitialPassword] = useState(
    ""
  );

  const openCreateLiaisonModal = (organizationId) => {
    setActiveOrganizationId(organizationId);
    setShowCreateLiaisonModal(true);
  };

  const createNewLiaison = async (e) => {
    e.preventDefault();
    setCreateLiaisonModalLoading(true);
    const newUserData = await createSuperuser({
      email: newLiaisonEmail,
      initialPassword: newLiaisonInitialPassword,
      organizationId: activeOrganizationId,
    });

    setCreateLiaisonModalLoading(false);
    setNewLiaisonEmail("");
    setNewLiaisonInitialPassword("");
    if (newUserData) {
      console.log("user created", newUserData);
      setShowCreateLiaisonModal(false);
    } else {
      // TODO show error
    }
  };

  useEffect(() => {
    const unsubscribe = db
      .collection("organizations")
      .onSnapshot(async (querySnapshot) => {
        const organizationData = await Promise.all(
          querySnapshot.docs.map(async (organizationDoc) => {
            const organization = organizationDoc.data();
            const liaisons = await Promise.all(
              organization.liaisons.map(async (liaisonRef) => {
                const liaisonDoc = await liaisonRef.get();
                const liaison = await liaisonDoc.data();
                return {
                  ...liaison,
                  id: liaisonDoc.id,
                  ref: liaisonRef,
                };
              })
            );
            return {
              ...organization,
              id: organizationDoc.id,
              liaisons,
            };
          })
        );
        setFetchedOrganizationData(organizationData);
      });

    return () => {
      unsubscribe();
    };
  }, []);

  let activeOrg = null;
  if (fetchedOrganizationData && activeOrganizationId) {
    const activeOrgIndex = fetchedOrganizationData.findIndex(
      (orgData) => orgData.id === activeOrganizationId
    );
    activeOrg =
      activeOrgIndex >= 0 ? fetchedOrganizationData[activeOrgIndex] : null;
  }

  return (
    <>
      <Modal
        show={showCreateLiaisonModal}
        onHide={() => setShowCreateLiaisonModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Liaison</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {activeOrg && (
            <Form onSubmit={createNewLiaison}>
              <Form.Group>
                <Form.Label>Email address:</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  disabled={createLiaisonModalLoading}
                  value={newLiaisonEmail}
                  onChange={(e) => setNewLiaisonEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Initial password:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter initial password"
                  disabled={createLiaisonModalLoading}
                  value={newLiaisonInitialPassword}
                  onChange={(e) => setNewLiaisonInitialPassword(e.target.value)}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={
              !newLiaisonEmail.match(
                /[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*/
              ) || createLiaisonModalLoading
            }
            variant="primary"
            onClick={createNewLiaison}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <h2>Admin home</h2>
      </Row>
      <Row>
        <Col>
          <ListGroup>
            {fetchedOrganizationData &&
              fetchedOrganizationData.map((org) => (
                <OrgEntry
                  key={org.id}
                  organizationData={org}
                  openCreateLiaisonModal={openCreateLiaisonModal}
                />
              ))}
          </ListGroup>
        </Col>
      </Row>
    </>
  );
};

export default AdminHome;
