import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Breakpoint } from "react-socks";

const hopeOptions = [
  "Spending time with friends and family",
  "Doing my favorite hobby",
  "Attending an important event",
  "Feeling at peace",
  "Being able to leave my home and go outside",
  "Keeping/maintaining my dignity",
  "Staying in my own home",
  "Something else"
];

export default ({ userData, updateUserData }) => {
  const { activity4 = {} } = userData;
  const { myHopes = [] } = activity4;

  let mediumUpCol1Array = [];
  let mediumUpCol2Array = [];

  for (let i = 0; i < Math.round(hopeOptions.length / 2); i++) {
    mediumUpCol1Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={myHopes.indexOf(hopeOptions[i]) !== -1}
          className="mb-3"
          id="medUp-option-button"
          onClick={() => {
            let myNewHopes = [];
            if (myHopes.indexOf(hopeOptions[i]) === -1) {
              myNewHopes[i] = hopeOptions[i];
              updateUserData({
                activity4: {
                  myHopes: myNewHopes
                }
              });
            } else {
              myNewHopes[i] = null;
              updateUserData({
                activity4: {
                  myHopes: myNewHopes
                }
              });
            }
          }}
        >
          {hopeOptions[i]}
        </Button>
      </Row>
    );
  }

  for (
    let i = Math.round(hopeOptions.length / 2);
    i < hopeOptions.length;
    i++
  ) {
    mediumUpCol2Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={myHopes.indexOf(hopeOptions[i]) !== -1}
          className="mb-3"
          id="medUp-option-button"
          onClick={() => {
            let myNewHopes = [];
            if (myHopes.indexOf(hopeOptions[i]) === -1) {
              myNewHopes[i] = hopeOptions[i];
              updateUserData({
                activity4: {
                  myHopes: myNewHopes
                }
              });
            } else {
              myNewHopes[i] = null;
              updateUserData({
                activity4: {
                  myHopes: myNewHopes
                }
              });
            }
          }}
        >
          {hopeOptions[i]}
        </Button>
      </Row>
    );
  }

  return (
    <>
      <div id="question-container">
        <h6>
          We often think of quality of life as being able to do things that we
          enjoy, and avoiding things that would make our day worse. Lily wanted
          to spend time with her family and do activities she found enjoyable.
          In the activity below, choose the things you hope for (or the things
          that make a "good day" for you).
        </h6>
      </div>
      <br />
      <br />
      <Breakpoint medium up>
        <div id="title-div">
          <h4>As you think about the future, what do you hope for?</h4>
          <h5>(select all that apply)</h5>
        </div>
      </Breakpoint>
      <Breakpoint small down>
        <h4>As you think about the future, what do you hope for?</h4>
        <h5>(select all that apply)</h5>
      </Breakpoint>
      <br />
      <Breakpoint small down>
        {hopeOptions.map((thing, i) => (
          <Row key={i}>
            <Button
              block
              variant="light"
              active={myHopes.indexOf(thing) !== -1}
              className="mb-3"
              id="option-button"
              onClick={() => {
                let myNewHopes = [];
                if (myHopes.indexOf(thing) === -1) {
                  myNewHopes[i] = thing;
                  updateUserData({
                    activity4: {
                      myHopes: myNewHopes
                    }
                  });
                } else {
                  myNewHopes[i] = null;
                  updateUserData({
                    activity4: {
                      myHopes: myNewHopes
                    }
                  });
                }
              }}
            >
              {thing}
            </Button>
          </Row>
        ))}
      </Breakpoint>
      <Breakpoint medium up>
        <Row>
          <Col className="mr-5">{mediumUpCol1Array}</Col>
          <Col>{mediumUpCol2Array}</Col>
        </Row>
      </Breakpoint>
    </>
  );
};
