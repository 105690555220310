import { db, auth, firebase } from "services/firebase";
import Filter from "bad-words";

const EMAIL_DOMAIN = "hospiceformeusernames.secret";

const POSSIBLE_CHARACTERS = "ABCDEFGHJKLMNPQRSTUVWXYZ23456789";

const wordFilter = new Filter();

const generatePasscode = (length = 4) => {
  let passcode = "";
  for (let i = 0; i < length; i++) {
    passcode += POSSIBLE_CHARACTERS.charAt(
      Math.floor(Math.random() * POSSIBLE_CHARACTERS.length)
    );
  }
  return wordFilter.isProfane(passcode) ? generatePasscode(length) : passcode;
};

const passcodeToEmail = passcode => {
  return `${passcodeToPassword(passcode)}@${EMAIL_DOMAIN}`;
};

const passcodeToPassword = passcode => {
  return passcode.padEnd(6, "0");
};

// create user:
// generate code
// use code to register user with fake email/password; if user already exists with code then regenerate and try again
// get user id from newly registered user
// insert data in users collection under user id

const generateNewUserAccount = (authInstance = auth) => {
  const passcode = generatePasscode();
  const email = passcodeToEmail(passcode);
  const password = passcodeToPassword(passcode);

  return authInstance
    .createUserWithEmailAndPassword(email, password)
    .then(userCredentials => {
      const { user } = userCredentials;
      return {
        user,
        passcode
      };
    })
    .catch(error => {
      if (error.code === "auth/email-already-in-use") {
        return generateNewUserAccount();
      } else {
        throw error;
      }
    });
};

export const deleteUser = async (userRef, cohortRef) => {
  try {
    await userRef.update({
      disabled: true
    });
    // if (cohortRef) {
    //   await cohortRef.update({
    //     users: firebase.firestore.FieldValue.arrayRemove(userRef)
    //   });
    // }
    // await userRef.delete();
    return true;
  } catch (error) {
    console.log("delete user error", error);
    return false;
  }
}

export const restoreUser = async (userRef, cohortRef) => {
  try {
    await userRef.update({
      disabled: false
    });
    return true;
  } catch (error) {
    console.log("restore user error", error);
    return false;
  }
}

export const createUser = async (
  cohortRef,
  userData = {},
  authInstance = auth
) => {
  const { user, passcode } = await generateNewUserAccount(authInstance);
  const usersRef = db.collection("users");
  const combinedUserData = {
    ...userData,
    passcode
  };
  if (cohortRef) combinedUserData.cohort = cohortRef;
  try {
    await usersRef.doc(user.uid).set(combinedUserData);
    const userRef = db.collection("users").doc(user.uid);
    if (cohortRef) {
      await cohortRef.update({
        users: firebase.firestore.FieldValue.arrayUnion(userRef)
      });
    }
    return combinedUserData;
  } catch (error) {
    // throw (error);
    console.log("create user error", error);
    return false;
  }
};

export const getCurrentUserData = async () => {
  // TODO cache this value locally and/or keep it synchronized locally (retrieve at login, update when update is called) to improve speed and prevent render issues
  if (!auth.currentUser) return null;
  const userDataDoc = await db
    .collection("users")
    .doc(auth.currentUser.uid)
    .get();
  if (userDataDoc.exists) return userDataDoc.data();
  return null;
};

export const getUserData = async uuid => {
  const userDataDoc = await db
    .collection("users")
    .doc(uuid)
    .get();
  if (userDataDoc.exists) return userDataDoc.data();
  return null;
};

export const updateCurrentUserData = async userData => {
  if (!auth.currentUser) return false;
  const usersRef = db.collection("users");
  try {
    await usersRef.doc(auth.currentUser.uid).set(userData);
    return true;
  } catch (error) {
    console.log("Error submitting user data:", error);
    return false;
    // throw (error);
  }
};

export const loginUser = async passcode => {
  const email = passcodeToEmail(passcode);
  const password = passcodeToPassword(passcode);

  try {
    await auth.signInWithEmailAndPassword(email, password);

    const userDataDoc = await db
      .collection("users")
      .doc(auth.currentUser.uid)
      .get();
    if (userDataDoc.exists) {
      const userData = await userDataDoc.data();
      if (!!userData.disabled) {
        await auth.signOut();
        throw new Error('User has been disabled');
      }
    }

    return true;
  } catch (error) {
    console.log("signin error: ", error);
    return false;
  }
};

export const logoutUser = async () => {
  await auth.signOut();
};

export const isUserLoggedIn = () => { };
