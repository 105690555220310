import React from "react";
import { Container } from "react-bootstrap";

export default props => (
  <>
    <Container
      id="main-box"
      xl={{ span: 6, offset: 3 }}
      lg={{ span: 6, offset: 3 }}
      md={{ span: 8, offset: 2 }}
      sm={{ span: 6, offset: 3 }}
    >
      <h4>About HoPE</h4>
      <p>
        Choosing whether hospice care is right for you can be a tough decision
        for you and your loved ones. HoPE, which stands for Hospice Preparation
        and Education, was designed by a passionate team of clinicians and
        experts at Duke University to help patients and families learn about
        what hospice is, what it can offer, and decide whether it is a good fit
        for you.
      </p>
      <p>
        We built HoPE with the generous research funding and support of the
        North Carolina Biotechnology Center.
      </p>
      <p id="about-tech-req">Technical requirements:</p>
      <p>
        In order to fully experience HoPE's features, your device must meet the
        following technical requirements:
      </p>
      <ul>
        <li>
          A device with Internet capabilities such as a computer, phone, or
          tablet.
        </li>
        <li>
          A browser of IE 10+, Edge 14+, Firefox 54+, Chrome 51+, Safari 10+, or
          Opera 38+.
        </li>
        <li>Videos contain sound. Please make sure to turn your volume on.</li>
        <li>
          Some activities will involve using a mouse, trackpad, or touch-screen.
        </li>
      </ul>
    </Container>
  </>
);
