import React from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';

export default () => (
  <Row className="justify-content-md-center">
    <Col align="center">
      <Spinner className="justify-content-md-center" animation="border" size="lg" />
      <h4 className="text-center">Loading...</h4>
    </Col>
  </Row>
);