import React, { Component } from "react";

export default class Page2 extends Component {
  render() {
    return (
      <>
        <h4>Thinking About Where to Receive Care</h4>
        <div className="video-container">
          <iframe
            title="page2Video"
            width="716"
            height="403"
            src="https://www.youtube-nocookie.com/embed/qrZwneC5anU?rel=0;modestbranding=1"
            frameBorder="0"
            allowFullScreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
          ></iframe>
        </div>
      </>
    );
  }
}
