import React from "react";
import { Switch, Route } from "react-router-dom";

import PrivateRoute from "components/PrivateRoute";

import Landing from "pages/Landing";
import About from "pages/About";
import Terms from "pages/Terms";
import ResourceLibrary from "pages/ResourceLibrary";
import PublicSummary from "pages/PublicSummary";

import { SUPERUSER_TYPES } from "services/admin";
import AdminLanding from "pages/Admin/AdminLanding";
import AdminHome from "pages/Admin/AdminHome";
import LiaisonHome from "pages/Admin/LiaisonHome";

import pageConfig from "config/pageConfig";

class Router extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/about" component={About} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/summary/:id" component={PublicSummary} />
        <Route exact path="/resource-library" component={ResourceLibrary} />

        <Route exact path="/admin" component={AdminLanding} />
        <PrivateRoute
          exact
          path="/admin/home"
          requiredType={SUPERUSER_TYPES.ADMIN}
          redirectTo="/admin"
          component={AdminHome}
        />
        <PrivateRoute
          exact
          path="/admin/liaison"
          requiredType={SUPERUSER_TYPES.LIAISON}
          redirectTo="/admin"
          component={LiaisonHome}
        />

        {pageConfig.map((entry, i) => (
          <PrivateRoute key={i} exact {...entry} />
        ))}
      </Switch>
    );
  }
}

export default Router;
