import React, { Component } from "react";

export default class Page6 extends Component {
  render() {
    return (
      <>
        <h4>The Hospice Care Team</h4>
        <div className="video-container">
          <iframe
            title="page6Video"
            width="716"
            height="403"
            src="https://www.youtube-nocookie.com/embed/d4doCwuPZJ4?rel=0;modestbranding=1"
            frameBorder="0"
            allowFullScreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
          ></iframe>
        </div>
      </>
    );
  }
}
