import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Form,
  Button,
  InputGroup,
  Spinner,
  Alert,
  Modal,
} from "react-bootstrap";
import pageConfig from "config/pageConfig";

import UserContext from "context/UserContext";

const LoginForm = ({
  history,
  redirectUrl,
  loading,
  setLoading,
  updateUserData,
  commitUserData,
  loginUser,
}) => {
  const [passcode, setPasscode] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    const userData = await loginUser(passcode);

    if (userData) {
      if (
        !userData.path &&
        pageConfig.find((page) => page.path === redirectUrl)
      ) {
        const visitedPaths = userData.visitedPaths || {};
        visitedPaths[redirectUrl] = true;
        await updateUserData({ path: redirectUrl, visitedPaths });
        await commitUserData();
      }

      history.push(userData.path || redirectUrl);
    } else {
      setLoading(false);
      setPasscode("");
      setError("Invalid code, please try again.");
    }
  };

  return (
    <Form className="pt-4" noValidate onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form.Group controlId="passcode">
        <InputGroup>
          <Form.Control
            type="text"
            placeholder="Enter your HoPE code"
            disabled={loading}
            value={passcode}
            onChange={(e) => setPasscode(e.target.value)}
          />
          <InputGroup.Append>
            <Button type="submit" disabled={passcode.length === 0 || loading}>
              {loading && passcode.length > 0 ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Starting...
                </>
              ) : (
                <>Continue</>
              )}
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form.Group>
    </Form>
  );
};

const CreateUserForm = ({
  history,
  redirectUrl,
  createUser,
  updateUserData,
  commitUserData,
  loading,
  setLoading,
}) => {
  const [error, setError] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCreateUser = async (e) => {
    setLoading(true);
    const createdUser = await createUser();
    if (!createdUser) {
      setLoading(false);
      setError(
        "Error during user creation. Please refresh the page and try again."
      );
    } else {
      if (pageConfig.find((page) => page.path === redirectUrl)) {
        const visitedPaths = createdUser.visitedPaths || {};
        visitedPaths[redirectUrl] = true;
        await updateUserData({ path: redirectUrl, visitedPaths });
        await commitUserData();
      }
      setLoading(false);
      history.push(redirectUrl);
    }
    handleClose(false);
  };

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <div id="request-new-code-button-div">
        <Button
          id="request-new-code-button"
          className="mb-2"
          variant="link"
          disabled={loading}
          onClick={handleShow}
        >
          Request new HoPE code
        </Button>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Requesting a new HoPE code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            If you were given a HoPE code but do not remember your code, contact
            your hospice liaison. You will need this code to access HoPE.
          </p>
          <p>
            If you did not receive a HoPE code, you can generate your new HoPE
            code below.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateUser}>
            Generate new HoPE code
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

/*
<CreateUserForm
  history={history}
  redirectUrl={redirectUrl}
  loading={loading}
  setLoading={setLoading}
  createUser={createUser}
  updateUserData={updateUserData}
  commitUserData={commitUserData}
  />
*/
//Above is the CreateUserForm for the landing page. This form will be OMITTED for pilot trial purposes solely.
//KEEP the CreateUserForm for subsequent use of the app.
//PLACE BELOW the LoginForm component below:

export default withRouter(({ redirectUrl = "/", history }) => {
  const [loading, setLoading] = useState(false);

  return (
    <UserContext.Consumer>
      {({ createUser, loginUser, updateUserData, commitUserData }) => (
        <>
          <LoginForm
            history={history}
            redirectUrl={redirectUrl}
            loading={loading}
            setLoading={setLoading}
            loginUser={loginUser}
            updateUserData={updateUserData}
            commitUserData={commitUserData}
          />
        </>
      )}
    </UserContext.Consumer>
  );
});
