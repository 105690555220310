import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import UserContext from "context/UserContext";
import LoginForm from "components/LoginForm";
import pageConfig from "config/pageConfig";
import { LinkContainer } from "react-router-bootstrap";
import { Breakpoint } from "react-socks";

import { ReactComponent as BannerLogo } from "HOPE_logo2.svg";

const Landing = ({ userData, updateUserData, commitUserData }) => {
  let currentPageIndex = -1;

  if (userData && userData.path) {
    currentPageIndex = pageConfig.findIndex(
      (page) => page.path === userData.path
    );
  }

  const updatePathAndCommitData = async (path) => {
    const visitedPaths = userData.visitedPaths || {};
    visitedPaths[path] = true;
    await updateUserData({ path, visitedPaths });
    await commitUserData();
  };

  const handleReturn = () => {
    updatePathAndCommitData(pageConfig[currentPageIndex].path);
  };

  return (
    <>
      <Breakpoint small down>
        <div id="landing-box">
          <Row className="justify-content-md-center pt-4">
            <Col md="auto">
              <BannerLogo width={null} height={null} />
              <br />
              <br />
              <br />
              <h4 id="landing-text">
                Helping you plan for <br />
                more good days
              </h4>
              <br />
              <div>
                {currentPageIndex !== -1 && userData ? (
                  <LinkContainer to={pageConfig[currentPageIndex].path}>
                    <Button block className="mb-2" onClick={handleReturn}>
                      Return to app
                    </Button>
                  </LinkContainer>
                ) : (
                  <LoginForm redirectUrl="/getting-started" />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Breakpoint>

      <Breakpoint large up>
        <div id="landing-box-big">
          <Row className="justify-content-md-center pt-4">
            <Col md="auto">
              <BannerLogo width={null} height={null} />
              <br />
              <br />
              <br />
              <h3 id="landing-text">
                Helping you plan for <br />
                more good days
              </h3>
              <br />
              <br />
              <div>
                {currentPageIndex !== -1 && userData ? (
                  <LinkContainer to={pageConfig[currentPageIndex].path}>
                    <Button block className="mb-2" onClick={handleReturn}>
                      Return to app
                    </Button>
                  </LinkContainer>
                ) : (
                  <LoginForm redirectUrl="/getting-started" />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Breakpoint>
      <Breakpoint medium only>
        <div id="landing-box-med">
          <Row className="justify-content-md-center pt-4">
            <Col md="auto">
              <BannerLogo width={null} height={null} />
              <br />
              <br />
              <br />
              <h4 id="landing-text">
                Helping you plan for <br />
                more good days
              </h4>
              <br />
              <br />
              <div>
                {currentPageIndex !== -1 && userData ? (
                  <LinkContainer to={pageConfig[currentPageIndex].path}>
                    <Button block className="mb-2" onClick={handleReturn}>
                      Return to app
                    </Button>
                  </LinkContainer>
                ) : (
                  <LoginForm redirectUrl="/getting-started" />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Breakpoint>
    </>
  );
};

export default (props) => (
  <UserContext.Consumer>
    {(userContextProps) => <Landing {...props} {...userContextProps} />}
  </UserContext.Consumer>
);
