import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import LoadingIndicator from 'components/LoadingIndicator';
import PrivateContainer from 'components/PrivateContainer';
import UserContext from 'context/UserContext';

const PrivateRoute = ({ key, exact, path, requiredType, component: Component, ...props }) => {
  const { initialUserFetch, isUserAuthenticated, redirectTo = "/", userData } = props;

  if (initialUserFetch) return (
    <LoadingIndicator />
  );

  const hasPermission = (requiredType && userData) ? requiredType === userData.type : true;

  if (!isUserAuthenticated || !hasPermission) return (<Redirect to={redirectTo} />);
  return (
    <Route exact={exact} path={path} render={routeProps => (
      <Component {...routeProps} {...props} />
    )} />
  );
}

export default (props) => (
  <PrivateContainer>
    <UserContext.Consumer>
      {(userContextProps) => (
        <PrivateRoute {...props} {...userContextProps} />
      )}
    </UserContext.Consumer>
  </PrivateContainer>
);