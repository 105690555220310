import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Breakpoint } from "react-socks";

const currentLivingOptions = [
  "In my own home by myself",
  "In my own home with others",
  "Nursing home",
  "Assisted living facility",
  "Somewhere else",
];

export default ({ userData, updateUserData }) => {
  const { activity7c = {} } = userData;
  const { myCurrentLiving = null } = activity7c;

  let mediumUpCol1Array = [];
  let mediumUpCol2Array = [];

  for (let i = 0; i < currentLivingOptions.length; i++) {
    mediumUpCol1Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={myCurrentLiving === currentLivingOptions[i]}
          className="mb-3"
          id="medUp-option-button-activity1"
          onClick={() => {
            updateUserData({
              activity7c: {
                myCurrentLiving: currentLivingOptions[i],
              },
            });
          }}
        >
          {currentLivingOptions[i]}
        </Button>
      </Row>
    );
  }

  for (let i = 0; i < currentLivingOptions.length; i++) {
    mediumUpCol2Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={myCurrentLiving === currentLivingOptions[i]}
          className="mb-3"
          id="medUp-option-button-activity1-phone"
          onClick={() => {
            updateUserData({
              activity7c: {
                myCurrentLiving: currentLivingOptions[i],
              },
            });
          }}
        >
          {currentLivingOptions[i]}
        </Button>
      </Row>
    );
  }

  return (
    <>
      <Breakpoint medium up>
        <div id="question-container">
          <h6>
            Lastly, it is helpful for your care team to better understand your
            living situation. From the choices below, select the one that best
            applies to you.
          </h6>
        </div>
        <br />
        <br />
        <div id="title-div">
          <h4>Where are you currently living?</h4>
          <h5>(select one)</h5>
        </div>
        <br />
        <Row>
          <Col>{mediumUpCol1Array}</Col>
        </Row>
      </Breakpoint>
      <Breakpoint small down>
        <div id="question-container">
          <h6>
            Lastly, it is helpful for your care team to better understand your
            living situation. In the choices below, select the one that best
            applies to you.
          </h6>
        </div>
        <br />
        <br />
        <h4>Where are you living now?</h4>
        <h5>(select one)</h5>
        <br />
        <Row>
          <Col>{mediumUpCol2Array}</Col>
        </Row>
      </Breakpoint>
    </>
  );
};
