import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Breakpoint } from "react-socks";

const careNeedOptions = [
  "Help them understand my illness and what to expect",
  "Discuss the importance of taking care of themselves",
  "Understand their hopes, worries, and fears",
  "Give tips about how to care for me",
  "Help with logistics (e.g., medications, equipment)",
  "Provide spiritual or emotional support",
  "Share resources to help put affairs in order",
  "Explain when and how to contact the hospice team",
  "Manage family dynamics",
  "Something else",
];

export default ({ userData, updateUserData }) => {
  const { activity3 = {} } = userData;
  const { myCareNeeds = [] } = activity3;

  let mediumUpCol1Array = [];
  let mediumUpCol2Array = [];

  for (let i = 0; i < Math.round(careNeedOptions.length / 2); i++) {
    mediumUpCol1Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={myCareNeeds.indexOf(careNeedOptions[i]) !== -1}
          className="mb-3"
          id="medUp-option-button"
          onClick={() => {
            let myNewCareNeeds = [];
            if (myCareNeeds.indexOf(careNeedOptions[i]) === -1) {
              myNewCareNeeds[i] = careNeedOptions[i];
              updateUserData({
                activity3: {
                  myCareNeeds: myNewCareNeeds,
                },
              });
            } else {
              myNewCareNeeds[i] = null;
              updateUserData({
                activity3: {
                  myCareNeeds: myNewCareNeeds,
                },
              });
            }
          }}
        >
          {careNeedOptions[i]}
        </Button>
      </Row>
    );
  }

  for (
    let i = Math.round(careNeedOptions.length / 2);
    i < careNeedOptions.length;
    i++
  ) {
    mediumUpCol2Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={myCareNeeds.indexOf(careNeedOptions[i]) !== -1}
          className="mb-3"
          id="medUp-option-button"
          onClick={() => {
            let myNewCareNeeds = [];
            if (myCareNeeds.indexOf(careNeedOptions[i]) === -1) {
              myNewCareNeeds[i] = careNeedOptions[i];
              updateUserData({
                activity3: {
                  myCareNeeds: myNewCareNeeds,
                },
              });
            } else {
              myNewCareNeeds[i] = null;
              updateUserData({
                activity3: {
                  myCareNeeds: myNewCareNeeds,
                },
              });
            }
          }}
        >
          {careNeedOptions[i]}
        </Button>
      </Row>
    );
  }

  return (
    <>
      <div id="question-container">
        <h6>
          In the last video, you learned about the ways the hospice team was
          helping Carlos’ family. In the activity below, pick the things that a
          hospice team could do to help support your loved ones.
        </h6>
      </div>
      <br />
      <br />
      <Breakpoint medium up>
        <div id="title-div">
          <h4>
            How could a hospice team support your caregivers or loved ones?
          </h4>
          <h5>(select all that apply)</h5>
        </div>
      </Breakpoint>
      <Breakpoint small down>
        <h4>How could a hospice team support your caregivers or loved ones?</h4>
        <h5>(select all that apply)</h5>
      </Breakpoint>
      <br />
      <Breakpoint small down>
        {careNeedOptions.map((thing, i) => (
          <Row key={i}>
            <Button
              block
              variant="light"
              active={myCareNeeds.indexOf(thing) !== -1}
              className="mb-3"
              id="option-button"
              onClick={() => {
                let myNewCareNeeds = [];
                if (myCareNeeds.indexOf(thing) === -1) {
                  myNewCareNeeds[i] = thing;
                  updateUserData({
                    activity3: {
                      myCareNeeds: myNewCareNeeds,
                    },
                  });
                } else {
                  myNewCareNeeds[i] = null;
                  updateUserData({
                    activity3: {
                      myCareNeeds: myNewCareNeeds,
                    },
                  });
                }
              }}
            >
              {thing}
            </Button>
          </Row>
        ))}
      </Breakpoint>
      <Breakpoint medium up>
        <Row>
          <Col className="mr-5">{mediumUpCol1Array}</Col>
          <Col>{mediumUpCol2Array}</Col>
        </Row>
      </Breakpoint>
    </>
  );
};
