import React from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Nav, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import UserContext from "context/UserContext";
import pageConfig from "config/pageConfig";
import { Breakpoint } from "react-socks";

const Footer = ({ userData, updateUserData, commitUserData }) => {
  const location = useLocation();

  let currentPageIndex = pageConfig.findIndex(
    (page) => page.path === location.pathname
  );

  if (currentPageIndex === -1 && userData && userData.path) {
    currentPageIndex = pageConfig.findIndex(
      (page) => page.path === userData.path
    );
  }

  if (currentPageIndex === -1) return null;

  const totalPageCount = pageConfig.length - 1;

  const previousPagePath =
    currentPageIndex > 0 ? pageConfig[currentPageIndex - 1].path : "";
  const nextPagePath =
    currentPageIndex < totalPageCount
      ? pageConfig[currentPageIndex + 1].path
      : "";

  const updatePathAndCommitData = async (path) => {
    const visitedPaths = userData.visitedPaths || {};
    visitedPaths[path] = true;
    await updateUserData({ path, visitedPaths });
    await commitUserData();
  };

  const handleReturn = () => {
    updatePathAndCommitData(pageConfig[currentPageIndex].path);
  };

  return (
    <>
      <Breakpoint medium only>
        {(location.pathname === "/about" ||
          location.pathname === "/terms" ||
          location.pathname === "/resource-library") &&
          userData && (
            <Navbar id="nav-bar-footer-buttons-small-return" fixed="bottom">
              <Nav className="ml-5">
                <LinkContainer to={pageConfig[currentPageIndex].path}>
                  <Button id="return-button" onClick={handleReturn}>
                    Return to app
                  </Button>
                </LinkContainer>
              </Nav>
            </Navbar>
          )}
        {location.pathname === "/getting-started" && userData && (
          <Navbar id="nav-bar-footer" fixed="bottom">
            <Nav />
            <Nav>
              <Nav.Item className="ml-4">
                <LinkContainer to={nextPagePath}>
                  <Button
                    id="next-button"
                    disabled={currentPageIndex >= pageConfig.length - 1}
                    onClick={() => updatePathAndCommitData(nextPagePath)}
                  >
                    Next
                  </Button>
                </LinkContainer>
              </Nav.Item>
            </Nav>
          </Navbar>
        )}
        {location.pathname === "/care-plan" && userData && (
          <Navbar id="nav-bar-footer" fixed="bottom">
            <Nav className="mr-5">
              <LinkContainer to={previousPagePath}>
                <Button
                  id="back-button"
                  disabled={currentPageIndex <= 0}
                  onClick={() => updatePathAndCommitData(previousPagePath)}
                >
                  Back
                </Button>
              </LinkContainer>
            </Nav>
            <Nav>
              <Nav.Item className="ml-4">
                <LinkContainer to="/">
                  <Button id="next-button">Finish</Button>
                </LinkContainer>
              </Nav.Item>
            </Nav>
          </Navbar>
        )}
        {location.pathname !== "/" &&
          location.pathname !== "/about" &&
          location.pathname !== "/terms" &&
          location.pathname !== "/getting-started" &&
          location.pathname !== "/resource-library" &&
          location.pathname !== "/care-plan" &&
          !location.pathname.includes("/admin") &&
          !location.pathname.includes("/summary") &&
          userData && (
            <Navbar id="nav-bar-footer" fixed="bottom">
              <Nav className="mr-5">
                <LinkContainer to={previousPagePath}>
                  <Button
                    id="back-button"
                    disabled={currentPageIndex <= 0}
                    onClick={() => updatePathAndCommitData(previousPagePath)}
                  >
                    Back
                  </Button>
                </LinkContainer>
              </Nav>
              <Nav>
                <Nav.Item className="ml-4">
                  <LinkContainer to={nextPagePath}>
                    <Button
                      id="next-button"
                      disabled={currentPageIndex >= pageConfig.length - 1}
                      onClick={() => updatePathAndCommitData(nextPagePath)}
                    >
                      Next
                    </Button>
                  </LinkContainer>
                </Nav.Item>
              </Nav>
            </Navbar>
          )}
      </Breakpoint>

      <Breakpoint large up>
        {(location.pathname === "/about" ||
          location.pathname === "/terms" ||
          location.pathname === "/resource-library") &&
          userData && (
            <Navbar id="nav-bar-footer-buttons-small-return" fixed="bottom">
              <Nav id="return-button-nav">
                <LinkContainer to={pageConfig[currentPageIndex].path}>
                  <Button id="return-button" onClick={handleReturn}>
                    Return to app
                  </Button>
                </LinkContainer>
              </Nav>
            </Navbar>
          )}
        {location.pathname === "/getting-started" && userData && (
          <Navbar id="nav-bar-footer" fixed="bottom">
            <Nav />
            <Nav>
              <Nav.Item className="ml-4">
                <LinkContainer to={nextPagePath}>
                  <Button
                    id="next-button"
                    disabled={currentPageIndex >= pageConfig.length - 1}
                    onClick={() => updatePathAndCommitData(nextPagePath)}
                  >
                    Next
                  </Button>
                </LinkContainer>
              </Nav.Item>
            </Nav>
          </Navbar>
        )}
        {location.pathname === "/care-plan" && userData && (
          <Navbar id="nav-bar-footer" fixed="bottom">
            <Nav className="mr-5">
              <LinkContainer to={previousPagePath}>
                <Button
                  id="back-button"
                  disabled={currentPageIndex <= 0}
                  onClick={() => updatePathAndCommitData(previousPagePath)}
                >
                  Back
                </Button>
              </LinkContainer>
            </Nav>
            <Nav>
              <Nav.Item className="ml-4">
                <LinkContainer to="/">
                  <Button id="next-button">Finish</Button>
                </LinkContainer>
              </Nav.Item>
            </Nav>
          </Navbar>
        )}
        {location.pathname !== "/" &&
          location.pathname !== "/about" &&
          location.pathname !== "/terms" &&
          location.pathname !== "/getting-started" &&
          location.pathname !== "/resource-library" &&
          location.pathname !== "/care-plan" &&
          !location.pathname.includes("/admin") &&
          !location.pathname.includes("/summary") &&
          userData && (
            <Navbar id="nav-bar-footer" fixed="bottom">
              <Nav className="mr-5">
                <LinkContainer to={previousPagePath}>
                  <Button
                    id="back-button"
                    disabled={currentPageIndex <= 0}
                    onClick={() => updatePathAndCommitData(previousPagePath)}
                  >
                    Back
                  </Button>
                </LinkContainer>
              </Nav>
              <Nav>
                <Nav.Item className="ml-4">
                  <LinkContainer to={nextPagePath}>
                    <Button
                      id="next-button"
                      disabled={currentPageIndex >= pageConfig.length - 1}
                      onClick={() => updatePathAndCommitData(nextPagePath)}
                    >
                      Next
                    </Button>
                  </LinkContainer>
                </Nav.Item>
              </Nav>
            </Navbar>
          )}
      </Breakpoint>

      <Breakpoint small down>
        {(location.pathname === "/about" ||
          location.pathname === "/terms" ||
          location.pathname === "/resource-library") &&
          userData && (
            <div>
              <Navbar id="nav-bar-footer-buttons-small-return" fixed="bottom">
                <Nav className="w-100">
                  <LinkContainer to={pageConfig[currentPageIndex].path}>
                    <Button block className="mx-5" onClick={handleReturn}>
                      Return to App
                    </Button>
                  </LinkContainer>
                </Nav>
              </Navbar>
            </div>
          )}
        {location.pathname === "/getting-started" && userData && (
          <Navbar id="nav-bar-footer-buttons-small" fixed="bottom">
            <Nav />
            <Nav>
              <Nav.Item>
                <LinkContainer to={nextPagePath}>
                  <Button
                    id="next-button"
                    disabled={currentPageIndex >= pageConfig.length - 1}
                    onClick={() => updatePathAndCommitData(nextPagePath)}
                  >
                    Next
                  </Button>
                </LinkContainer>
              </Nav.Item>
            </Nav>
          </Navbar>
        )}
        {location.pathname === "/care-plan" && userData && (
          <Navbar id="nav-bar-footer-buttons-small" fixed="bottom">
            <Nav className="mr-5">
              <LinkContainer to={previousPagePath}>
                <Button
                  id="back-button-phone"
                  disabled={currentPageIndex <= 0}
                  onClick={() => updatePathAndCommitData(previousPagePath)}
                >
                  Back
                </Button>
              </LinkContainer>
            </Nav>
            <Nav>
              <Nav.Item className="ml-4">
                <LinkContainer to="/">
                  <Button id="next-button">Finish</Button>
                </LinkContainer>
              </Nav.Item>
            </Nav>
          </Navbar>
        )}
        {location.pathname !== "/" &&
          location.pathname !== "/about" &&
          location.pathname !== "/terms" &&
          location.pathname !== "/getting-started" &&
          location.pathname !== "/resource-library" &&
          location.pathname !== "/care-plan" &&
          !location.pathname.includes("/admin") &&
          !location.pathname.includes("/summary") &&
          userData && (
            <Navbar id="nav-bar-footer-buttons-small" fixed="bottom">
              <Nav>
                <LinkContainer to={previousPagePath}>
                  <Button
                    disabled={currentPageIndex <= 0}
                    onClick={() => updatePathAndCommitData(previousPagePath)}
                    id="back-button-phone"
                  >
                    Back
                  </Button>
                </LinkContainer>
              </Nav>
              <Nav>
                <Nav.Item>
                  <LinkContainer to={nextPagePath}>
                    <Button
                      id="next-button"
                      disabled={currentPageIndex >= pageConfig.length - 1}
                      onClick={() => updatePathAndCommitData(nextPagePath)}
                    >
                      Next
                    </Button>
                  </LinkContainer>
                </Nav.Item>
              </Nav>
            </Navbar>
          )}
      </Breakpoint>
    </>
  );
};

export default () => (
  <UserContext.Consumer>
    {({ userData, updateUserData, commitUserData }) => (
      <Footer
        userData={userData}
        updateUserData={updateUserData}
        commitUserData={commitUserData}
      />
    )}
  </UserContext.Consumer>
);
