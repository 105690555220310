import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import ReactCardFlip from "react-card-flip";

export default () => {
  const [flipStatus1, setFlipStatus1] = useState(false);
  const [flipStatus2, setFlipStatus2] = useState(false);
  const [flipStatus3, setFlipStatus3] = useState(false);
  const [flipStatus4, setFlipStatus4] = useState(false);
  const [flipStatus5, setFlipStatus5] = useState(false);
  const [flipStatus6, setFlipStatus6] = useState(false);
  const [flipStatus7, setFlipStatus7] = useState(false);
  const [flipStatus8, setFlipStatus8] = useState(false);

  const handleClick1 = () => {
    !flipStatus1 ? setFlipStatus1(true) : setFlipStatus1(false);
  };
  const handleClick2 = () => {
    !flipStatus2 ? setFlipStatus2(true) : setFlipStatus2(false);
  };
  const handleClick3 = () => {
    !flipStatus3 ? setFlipStatus3(true) : setFlipStatus3(false);
  };
  const handleClick4 = () => {
    !flipStatus4 ? setFlipStatus4(true) : setFlipStatus4(false);
  };
  const handleClick5 = () => {
    !flipStatus5 ? setFlipStatus5(true) : setFlipStatus5(false);
  };
  const handleClick6 = () => {
    !flipStatus6 ? setFlipStatus6(true) : setFlipStatus6(false);
  };
  const handleClick7 = () => {
    !flipStatus7 ? setFlipStatus7(true) : setFlipStatus7(false);
  };
  const handleClick8 = () => {
    !flipStatus8 ? setFlipStatus8(true) : setFlipStatus8(false);
  };

  return (
    <>
      <h4>Common Questions about Hospice Care</h4>
      <p>
        For the list of questions below, click or tap the “Learn more” button to
        read the answer.
      </p>
      <br />
      <ReactCardFlip isFlipped={flipStatus1} flipDirection="vertical">
        <div key="front">
          <Card>
            <Card.Header as="h5">
              <Button id="flip-toggler" onClick={handleClick1} size="sm">
                Learn more{" "}
              </Button>
              Who can receive hospice care?
            </Card.Header>
          </Card>
        </div>
        <div key="back">
          <Card>
            <Card.Body>
              <Card.Text>
                <strong>Who can receive hospice care?</strong>
                <br />
                Any person with a serious medical condition where a clinician
                estimates the person is in the last 6 months of life if the
                disease runs its usual course. However, people can receive
                hospice for as long as they meet these criteria, even if that
                turns out to be more than 6 months.
              </Card.Text>
              <Button id="flip-toggler" onClick={handleClick1} size="sm">
                Back{" "}
              </Button>
            </Card.Body>
          </Card>
        </div>
      </ReactCardFlip>
      <br />
      <ReactCardFlip isFlipped={flipStatus2} flipDirection="vertical">
        <div key="front">
          <Card>
            <Card.Header as="h5">
              <Button id="flip-toggler" onClick={handleClick2} size="sm">
                Learn more{" "}
              </Button>
              Who pays for hospice care?
            </Card.Header>
          </Card>
        </div>
        <div key="back">
          <Card>
            <Card.Body>
              <Card.Text>
                <strong>Who pays for hospice care?</strong>
                <br />
                Hospice care is covered by most insurers, including Medicare,
                Medicaid, and private insurance plans. The hospice benefit
                covers all the care and services provided by the core hospice
                team, as well as medications, equipment, and supplies related to
                the life-limiting diagnosis. The hospice team works with the
                patient to explore coverage for other services and care that may
                be necessary.
              </Card.Text>
              <Button id="flip-toggler" onClick={handleClick2} size="sm">
                Back{" "}
              </Button>
            </Card.Body>
          </Card>
        </div>
      </ReactCardFlip>
      <br />
      <ReactCardFlip isFlipped={flipStatus3} flipDirection="vertical">
        <div key="front">
          <Card>
            <Card.Header as="h5">
              <Button id="flip-toggler" onClick={handleClick3} size="sm">
                Learn more{" "}
              </Button>
              Is hospice new?
            </Card.Header>
          </Card>
        </div>
        <div key="back">
          <Card>
            <Card.Body>
              <Card.Text>
                <strong>Is hospice new?</strong>
                <br />
                Hospice is not new. The idea of hospice has been around for over
                50 years, and more than 1.5 million Americans choose hospice
                every year to maximize their quality of life when facing a
                serious illness. Hospice allows patients to make several choices
                about the type of care they want to receive, including which
                hospice team members to involve and how often to receive care.
              </Card.Text>
              <Button id="flip-toggler" onClick={handleClick3} size="sm">
                Back{" "}
              </Button>
            </Card.Body>
          </Card>
        </div>
      </ReactCardFlip>
      <br />
      <ReactCardFlip isFlipped={flipStatus4} flipDirection="vertical">
        <div key="front">
          <Card>
            <Card.Header as="h5">
              <Button id="flip-toggler" onClick={handleClick4} size="sm">
                Learn more{" "}
              </Button>
              Can I still see my usual clinicians while receiving hospice care?
            </Card.Header>
          </Card>
        </div>
        <div key="back">
          <Card>
            <Card.Body>
              <Card.Text>
                <strong>
                  Can I still see my usual clinicians while receiving hospice
                  care?
                </strong>
                <br />
                In most cases, you can choose to continue speaking with or
                visiting your usual clinical team. For most patients, the
                hospice team can provide all the services and medications you
                may need for comfort and support. Talk with your hospice team
                about how you want to include your other physicians and
                clinicians in your care.
              </Card.Text>
              <Button id="flip-toggler" onClick={handleClick4} size="sm">
                Back{" "}
              </Button>
            </Card.Body>
          </Card>
        </div>
      </ReactCardFlip>
      <br />
      <ReactCardFlip isFlipped={flipStatus5} flipDirection="vertical">
        <div key="front">
          <Card>
            <Card.Header as="h5">
              <Button id="flip-toggler" onClick={handleClick5} size="sm">
                Learn more{" "}
              </Button>
              Can I change my mind while on hospice?
            </Card.Header>
          </Card>
        </div>
        <div key="back">
          <Card>
            <Card.Body>
              <Card.Text>
                <strong>Can I change my mind while on hospice?</strong>
                <br />
                Yes. You can change your mind at any time. If you no longer want
                to receive hospice services, or if you feel you no longer need
                hospice services, talk with your hospice team. They can help
                find other care if needed.
              </Card.Text>
              <Button id="flip-toggler" onClick={handleClick5} size="sm">
                Back{" "}
              </Button>
            </Card.Body>
          </Card>
        </div>
      </ReactCardFlip>
      <br />
      <ReactCardFlip isFlipped={flipStatus6} flipDirection="vertical">
        <div key="front">
          <Card>
            <Card.Header as="h5">
              <Button id="flip-toggler" onClick={handleClick6} size="sm">
                Learn more{" "}
              </Button>
              How will hospice care for my family and friends?
            </Card.Header>
          </Card>
        </div>
        <div key="back">
          <Card>
            <Card.Body>
              <Card.Text>
                <strong>
                  How will hospice care for my family and friends?
                </strong>
                <br />
                The hospice team will also support your loved ones. Social
                workers and spiritual care counselors can meet with you and your
                family members individually or as a group to help you through
                this journey. You may also take comfort in knowing that your
                loved ones can receive grief support from hospice for a year
                after your death. Support is offered through phone calls,
                mailings, and in-person visits as needed.
              </Card.Text>
              <Button id="flip-toggler" onClick={handleClick6} size="sm">
                Back{" "}
              </Button>
            </Card.Body>
          </Card>
        </div>
      </ReactCardFlip>
      <br />
      <ReactCardFlip isFlipped={flipStatus7} flipDirection="vertical">
        <div key="front">
          <Card>
            <Card.Header as="h5">
              <Button id="flip-toggler" onClick={handleClick7} size="sm">
                Learn more{" "}
              </Button>
              Where is hospice care provided?
            </Card.Header>
          </Card>
        </div>
        <div key="back">
          <Card>
            <Card.Body>
              <Card.Text>
                <strong>Where is hospice care provided?</strong>
                <br />
                Most hospice care is provided in the place you call home. You
                can receive care in your own home, the home of a friend or
                relative, or in a facility like a nursing home. The hospice
                benefit also includes coverage for other places to receive care
                if needed. Inpatient settings are available if more symptom
                management is needed, or to provide a break to caregivers for
                short periods of time. Since the hospice care plan is about you
                and your family, it changes with you. Your hospice team will
                help you find the most helpful type of care and place for care
                as your needs change.
              </Card.Text>
              <Button id="flip-toggler" onClick={handleClick7} size="sm">
                Back{" "}
              </Button>
            </Card.Body>
          </Card>
        </div>
      </ReactCardFlip>
      <br />
      <ReactCardFlip isFlipped={flipStatus8} flipDirection="vertical">
        <div key="front">
          <Card>
            <Card.Header as="h5">
              <Button id="flip-toggler" onClick={handleClick8} size="sm">
                Learn more{" "}
              </Button>
              Is hospice only for people in the final chapters of life?
            </Card.Header>
          </Card>
        </div>
        <div key="back">
          <Card>
            <Card.Body>
              <Card.Text>
                <strong>
                  Is hospice only for people in the final chapters of life?
                </strong>
                <br />
                Hospice is for any person who meets the criteria for enrollment.
                Studies show that the earlier a person enters hospice, the
                better they feel physically and emotionally. Also, the earlier
                you involve the hospice team in your care, the longer you and
                your loved ones can receive the support that is most helpful to
                you.
              </Card.Text>
              <Button id="flip-toggler" onClick={handleClick8} size="sm">
                Back{" "}
              </Button>
            </Card.Body>
          </Card>
        </div>
      </ReactCardFlip>
    </>
  );
};
