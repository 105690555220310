import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Breakpoint } from "react-socks";

const currentServiceOptions = [
  "Doctor visits in my home",
  "Nurse visits in my home",
  "Personal care aides",
  "Physical/Occupational therapy",
  "Speech therapy",
  "Pulmonary rehabilitation",
  "Cardiac rehabilitation",
  "Dialysis",
  "Regular blood transfusions",
  "Treatment through a clinical or experimental trial",
  "Another type of therapy or treatment",
  "Palliative care",
  "Social work",
  "Something else"
];

export default ({ userData, updateUserData }) => {
  const { activity7 = {} } = userData;
  const { myCurrentServices = [] } = activity7;

  let mediumUpCol1Array = [];
  let mediumUpCol2Array = [];

  for (let i = 0; i < Math.round(currentServiceOptions.length / 2); i++) {
    mediumUpCol1Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={myCurrentServices.indexOf(currentServiceOptions[i]) !== -1}
          className="mb-3"
          id="medUp-option-button"
          onClick={() => {
            let myNewCurrentServices = [];
            if (myCurrentServices.indexOf(currentServiceOptions[i]) === -1) {
              myNewCurrentServices[i] = currentServiceOptions[i];
              updateUserData({
                activity7: {
                  myCurrentServices: myNewCurrentServices
                }
              });
            } else {
              myNewCurrentServices[i] = null;
              updateUserData({
                activity7: {
                  myCurrentServices: myNewCurrentServices
                }
              });
            }
          }}
        >
          {currentServiceOptions[i]}
        </Button>
      </Row>
    );
  }

  for (
    let i = Math.round(currentServiceOptions.length / 2);
    i < currentServiceOptions.length;
    i++
  ) {
    mediumUpCol2Array.push(
      <Row key={i}>
        <Button
          block
          variant="light"
          active={myCurrentServices.indexOf(currentServiceOptions[i]) !== -1}
          className="mb-3"
          id="medUp-option-button"
          onClick={() => {
            let myNewCurrentServices = [];
            if (myCurrentServices.indexOf(currentServiceOptions[i]) === -1) {
              myNewCurrentServices[i] = currentServiceOptions[i];
              updateUserData({
                activity7: {
                  myCurrentServices: myNewCurrentServices
                }
              });
            } else {
              myNewCurrentServices[i] = null;
              updateUserData({
                activity7: {
                  myCurrentServices: myNewCurrentServices
                }
              });
            }
          }}
        >
          {currentServiceOptions[i]}
        </Button>
      </Row>
    );
  }

  return (
    <>
      <div id="question-container">
        <h6>
          Knowing what kinds of services you are currently receiving will help
          the hospice team create a plan tailored to your needs. Look at the
          list below and select the ones that apply to you.
        </h6>
      </div>
      <br />
      <br />
      <Breakpoint medium up>
        <div id="title-div">
          <h4>What services are you currently receiving?</h4>
          <h5>(select all that apply)</h5>
        </div>
      </Breakpoint>
      <Breakpoint small down>
        <h4>What services are you currently receiving?</h4>
        <h5>(select all that apply)</h5>
      </Breakpoint>
      <br />
      <Breakpoint medium up>
        <Row>
          <Col className="mr-5">{mediumUpCol1Array}</Col>
          <Col>{mediumUpCol2Array}</Col>
        </Row>
      </Breakpoint>
      <Breakpoint small down>
        {currentServiceOptions.map((thing, i) => (
          <Row key={i}>
            <Button
              block
              variant="light"
              active={myCurrentServices.indexOf(thing) !== -1}
              className="mb-3"
              id="option-button"
              onClick={() => {
                let myNewCurrentServices = [];
                if (myCurrentServices.indexOf(thing) === -1) {
                  myNewCurrentServices[i] = thing;
                  updateUserData({
                    activity7: {
                      myCurrentServices: myNewCurrentServices
                    }
                  });
                } else {
                  myNewCurrentServices[i] = null;
                  updateUserData({
                    activity7: {
                      myCurrentServices: myNewCurrentServices
                    }
                  });
                }
              }}
            >
              {thing}
            </Button>
          </Row>
        ))}
      </Breakpoint>
    </>
  );
};
