import React from "react";
import { Row, Table, Button } from "react-bootstrap";
import { Breakpoint } from "react-socks";

const itemOptions = [
  "The name of a family member or friend who can provide care for me when the hospice team is not around",
  "Family contact information",
  "Your health insurance information",
  "A list of your medications and equipment",
  "Your advance directives (e.g., living will, healthcare power of attorney)",
  "A list of your health care providers",
];

export default ({ userData, updateUserData }) => {
  const { activity9 = [] } = userData;

  const {
    caregiver = null,
    contacts = null,
    insurance = null,
    medsEquip = null,
    advDirec = null,
    providers = null,
  } = activity9;

  return (
    <>
      <div id="question-container">
        <h6>
          The hospice team may ask you for different pieces of information, and
          it is helpful to gather these ahead of meeting with the hospice team.
          For each of the items below, pick whether you “do have” or “don’t
          have” each item.
        </h6>
      </div>
      <br />
      <br />
      <Breakpoint medium up>
        <div id="title-div">
          <h4>
            For the following list of items, do you have these prepared and
            ready to share with the hospice team?
          </h4>
        </div>
      </Breakpoint>
      <Breakpoint small down>
        <h4>
          For the following list of items, do you have these prepared and ready
          to share with the hospice team?
        </h4>
      </Breakpoint>
      <Row>
        <div className="table-responsive">
          <Breakpoint medium up>
            <Table id="activity9-table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th colSpan="2">Status of item</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{itemOptions[0]}</td>
                  <td>
                    <Button
                      variant="light"
                      active={caregiver === "Do Have"}
                      className="mb-3"
                      id="medUp-option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            caregiver: "Do Have",
                          },
                        });
                      }}
                    >
                      Do Have
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="light"
                      active={caregiver === "Don't Have"}
                      className="mb-3"
                      id="medUp-option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            caregiver: "Don't Have",
                          },
                        });
                      }}
                    >
                      Don't Have
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>{itemOptions[1]}</td>
                  <td>
                    <Button
                      variant="light"
                      active={contacts === "Do Have"}
                      className="mb-3"
                      id="medUp-option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            contacts: "Do Have",
                          },
                        });
                      }}
                    >
                      Do Have
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="light"
                      active={contacts === "Don't Have"}
                      className="mb-3"
                      id="medUp-option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            contacts: "Don't Have",
                          },
                        });
                      }}
                    >
                      Don't Have
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>{itemOptions[2]}</td>
                  <td>
                    <Button
                      variant="light"
                      active={insurance === "Do Have"}
                      className="mb-3"
                      id="medUp-option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            insurance: "Do Have",
                          },
                        });
                      }}
                    >
                      Do Have
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="light"
                      active={insurance === "Don't Have"}
                      className="mb-3"
                      id="medUp-option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            insurance: "Don't Have",
                          },
                        });
                      }}
                    >
                      Don't Have
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>{itemOptions[3]}</td>
                  <td>
                    <Button
                      variant="light"
                      active={medsEquip === "Do Have"}
                      className="mb-3"
                      id="medUp-option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            medsEquip: "Do Have",
                          },
                        });
                      }}
                    >
                      Do Have
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="light"
                      active={medsEquip === "Don't Have"}
                      className="mb-3"
                      id="medUp-option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            medsEquip: "Don't Have",
                          },
                        });
                      }}
                    >
                      Don't Have
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>{itemOptions[4]}</td>
                  <td>
                    <Button
                      variant="light"
                      active={advDirec === "Do Have"}
                      className="mb-3"
                      id="medUp-option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            advDirec: "Do Have",
                          },
                        });
                      }}
                    >
                      Do Have
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="light"
                      active={advDirec === "Don't Have"}
                      className="mb-3"
                      id="medUp-option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            advDirec: "Don't Have",
                          },
                        });
                      }}
                    >
                      Don't Have
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>{itemOptions[5]}</td>
                  <td>
                    <Button
                      variant="light"
                      active={providers === "Do Have"}
                      className="mb-3"
                      id="medUp-option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            providers: "Do Have",
                          },
                        });
                      }}
                    >
                      Do Have
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="light"
                      active={providers === "Don't Have"}
                      className="mb-3"
                      id="medUp-option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            providers: "Don't Have",
                          },
                        });
                      }}
                    >
                      Don't Have
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Breakpoint>

          <Breakpoint small down>
            <Table>
              <tbody>
                <tr>
                  <th colSpan="2">{itemOptions[0]}</th>
                </tr>
                <tr>
                  <td className="w-50">
                    <Button
                      block
                      variant="light"
                      active={caregiver === "Do Have"}
                      className="mb-3"
                      id="option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            caregiver: "Do Have",
                          },
                        });
                      }}
                    >
                      Do Have
                    </Button>
                  </td>
                  <td>
                    <Button
                      block
                      variant="light"
                      active={caregiver === "Don't Have"}
                      className="mb-3"
                      id="option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            caregiver: "Don't Have",
                          },
                        });
                      }}
                    >
                      Don't Have
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th colSpan="2">{itemOptions[1]}</th>
                </tr>
                <tr>
                  <td className="w-50">
                    <Button
                      block
                      variant="light"
                      active={contacts === "Do Have"}
                      className="mb-3"
                      id="option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            contacts: "Do Have",
                          },
                        });
                      }}
                    >
                      Do Have
                    </Button>
                  </td>
                  <td>
                    <Button
                      block
                      variant="light"
                      active={contacts === "Don't Have"}
                      className="mb-3"
                      id="option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            contacts: "Don't Have",
                          },
                        });
                      }}
                    >
                      Don't Have
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th colSpan="2">{itemOptions[2]}</th>
                </tr>
                <tr>
                  <td className="w-50">
                    <Button
                      block
                      variant="light"
                      active={insurance === "Do Have"}
                      className="mb-3"
                      id="option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            insurance: "Do Have",
                          },
                        });
                      }}
                    >
                      Do Have
                    </Button>
                  </td>
                  <td>
                    <Button
                      block
                      variant="light"
                      active={insurance === "Don't Have"}
                      className="mb-3"
                      id="option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            insurance: "Don't Have",
                          },
                        });
                      }}
                    >
                      Don't Have
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th colSpan="2">{itemOptions[3]}</th>
                </tr>
                <tr>
                  <td className="w-50">
                    <Button
                      block
                      variant="light"
                      active={medsEquip === "Do Have"}
                      className="mb-3"
                      id="option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            medsEquip: "Do Have",
                          },
                        });
                      }}
                    >
                      Do Have
                    </Button>
                  </td>
                  <td>
                    <Button
                      block
                      variant="light"
                      active={medsEquip === "Don't Have"}
                      className="mb-3"
                      id="option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            medsEquip: "Don't Have",
                          },
                        });
                      }}
                    >
                      Don't Have
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th colSpan="2">{itemOptions[4]}</th>
                </tr>
                <tr>
                  <td className="w-50">
                    <Button
                      block
                      variant="light"
                      active={advDirec === "Do Have"}
                      className="mb-3"
                      id="option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            advDirec: "Do Have",
                          },
                        });
                      }}
                    >
                      Do Have
                    </Button>
                  </td>
                  <td>
                    <Button
                      block
                      variant="light"
                      active={advDirec === "Don't Have"}
                      className="mb-3"
                      id="option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            advDirec: "Don't Have",
                          },
                        });
                      }}
                    >
                      Don't Have
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th colSpan="2">{itemOptions[5]}</th>
                </tr>
                <tr>
                  <td className="w-50">
                    <Button
                      block
                      variant="light"
                      active={providers === "Do Have"}
                      className="mb-3"
                      id="option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            providers: "Do Have",
                          },
                        });
                      }}
                    >
                      Do Have
                    </Button>
                  </td>
                  <td>
                    <Button
                      block
                      variant="light"
                      active={providers === "Don't Have"}
                      className="mb-3"
                      id="option-button"
                      onClick={() => {
                        updateUserData({
                          activity9: {
                            providers: "Don't Have",
                          },
                        });
                      }}
                    >
                      Don't Have
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Breakpoint>
        </div>
      </Row>
    </>
  );
};
